import { JsonClientEndpoint } from "@app/shared";
import { useModel } from "../../../hooks";
import { CheckboxInput, Input, InputRow, NumericInput, Select } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";

type EditJsonClientEndpointProps = {
} & ClientEndpointEditorProps;

const jsonVersionOptions = [{ label: "V2", value: 2 }, { label: "V1", value: 1 }];

export const EditJsonClientEndpoint: React.FC<EditJsonClientEndpointProps> = (props) => {
    const endpointWithDefaults = !!!props.endpoint?.id ? { ...props.endpoint, jsonVersion: 2 } : props.endpoint;
    const [endpoint, helper] = useModel(JsonClientEndpoint, endpointWithDefaults as JsonClientEndpoint);
    const onChange = (newEndpoint: Partial<JsonClientEndpoint>) => {
        props.onChange(newEndpoint as JsonClientEndpoint);
    };
    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
            <InputRow><Input label="Endpoint URL" {...helper.bindingsFor("endpointUrl", onChange)} /></InputRow>
            <InputRow><Select label="JSON Version" options={jsonVersionOptions} {...helper.bindingsFor("jsonVersion", onChange)} /></InputRow>
            <InputRow><Input label="HMAC Secret" placeholder="HMAC shared secret key" {...helper.bindingsFor("sharedSecretKey", onChange)} /></InputRow>
            <InputRow><Input label="Custom Auth Header" placeholder="MY_AUTH_HEADER=authValue" {...helper.bindingsFor("customAuthHeader", onChange)} /></InputRow>
            <InputRow><CheckboxInput label="Enable Heartbeat" {...helper.bindingsFor("enableHeartbeat", onChange)} /></InputRow>
            {endpoint.enableHeartbeat &&
                <InputRow><NumericInput label="Seconds Between Heartbeat" {...helper.bindingsFor("secondsBetweenHearbeat", onChange)} /></InputRow>}
        </>
    );
}