import { ServerError, UnauthorizedError, useIsMounted, UserError } from "@app/shared";
import { validate, ValidationError } from "class-validator";
import _ from "lodash";
import { ComponentType, FormEvent, useEffect, useState } from "react";
import { useErrors } from ".";
import { ModelValidator } from "../components/shared/inputs/inputTypes";


export type EventTypes = FormEvent<FormControlElement> | Date | null;
type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
type ModelUpdater<T> = (key: keyof Partial<T>, value: (Partial<T>[keyof T]) | ((prev: Partial<T>) => Partial<T>[keyof T])) => void;
type HandlerFactory<T> = <EventType extends EventTypes>(key: keyof Partial<T>, onChangeCallback?: (newVal: Partial<T>) => void) => ((event: EventType | Partial<T>[keyof T]) => void);
export type Model<T extends {}> = { new(): T };
type ValidateWithAction = (action: () => Promise<void>) => Promise<void>;

type BindingFactory<T> = <EventType extends EventTypes>(key: keyof Partial<T>, onChangeCallback?: (newVal: Partial<T>) => void) => {
    value: string | undefined;
    fieldName: string;
    onChange: ((event: EventType | Partial<T>[keyof T]) => void);
    validateModel: ModelValidator;
    validationError: ValidationError | undefined;
}

export type ModelHelpers<T> = {
    bindingsFor: BindingFactory<T>;
    handlerFor: HandlerFactory<T>;
    updateModel: ModelUpdater<T>;
    replaceModel: (newModel: T) => void;
    validateModel: ModelValidator;
    validateWithAction: ValidateWithAction;
    ErrorSummary: ComponentType;
    setSummaryError: (errors: string[] | undefined) => void;
    setShowDefaultSummary: (show: boolean) => void;
    modelContainerClassNames: string | undefined;
}

function createInstance<T extends {}>(type: Model<T>, defaults: Partial<T>): Partial<T> {
    var instance = new type();
    Object.assign(instance, defaults);

    return instance;
}

export function useModel<T extends {}>(
    type: Model<T>,
    defaults: Partial<T> = {},
): [Partial<T>, ModelHelpers<T>] {
    const isMounted = useIsMounted();
    const [model, setModel] = useState(() => createInstance(type, defaults as Partial<T>));
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [ErrorSummary, setErrorSummary] = useErrors();
    const [showDefaultSummary, setShowDefaultSummary] = useState(true);

    const modelContainerClassNames = hasSubmitted ? 'has-been-validated' : undefined;

    const validateModel = async () => {
        const newErrors = await validate(model);

        if (isMounted.current) {
            setValidationErrors(newErrors);
        }


        if (newErrors.length > 0 && process.env.NODE_ENV !== 'production') {
            console.log('Error validating model', model);
            console.log('Validation errors', newErrors);
        }

        return newErrors.length === 0;
    };

    useEffect(() => {
        validateModel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const replaceModel = (newModel: T) => {
        setValidationErrors([]);
        setErrorSummary(undefined);
        setModel(newModel);
    }

    const updateModel: ModelUpdater<T> = (key, value) => {

        if (typeof (value) == "function") {
            //console.log("Setting " + key.toString() + " to ", (value as Function)(model));
            setModel(prev => (createInstance(type, { ...prev, [key]: (value as Function)(prev) })));
        }
        else {
            //console.log("Setting " + key.toString() + " to ", value);
            setModel(prev => {
                var temp = createInstance(type, { ...prev, [key]: value });
                console.log("New model is ", temp);
                return (temp);
            });
        }
    };

    const handlerFor: HandlerFactory<T> = (key, onChangeCallback) => {
        return (eventOrValue) => {
            var value = eventOrValue;

            if (eventOrValue && (eventOrValue as any).target && (value as any).target.type === 'checkbox')
                value = (eventOrValue as any).target.checked
            else if (eventOrValue && (eventOrValue as any).currentTarget)
                value = (eventOrValue as any).currentTarget?.value
            else if (eventOrValue && (eventOrValue as any).target)
                value = (eventOrValue as any).target?.value

            const newModel = createInstance(type, { ...model, [key]: (value === "" ? null : value) });
            setModel(newModel);
            onChangeCallback?.(newModel);
        }
    };

    const bindingsFor: BindingFactory<T> = (key, onChangeCallback) => {
        const currentValue = model[key] ?? '';
        const value = `${currentValue}`;
        const checked = currentValue === true;
        const fieldName = `${key.toString()}`;
        const validationError = _.find(validationErrors, error => error.property === key);

        var onChange = handlerFor(key, onChangeCallback);

        return { checked, value, onChange, fieldName, validateModel, validationError };
    };
    const setSummaryError = (newErrors: string[] | undefined) => {
        if (!newErrors) {
            setHasSubmitted(false);
        }
        setErrorSummary(newErrors);
    };
    const validateWithAction: ValidateWithAction = async (action) => {
        setErrorSummary(undefined);
        setHasSubmitted(true);

        const isValid = await validateModel();
        if (!isValid) {
            if (showDefaultSummary) {
                setErrorSummary('Please correct the following errors and try again.');
            }
            return;
        }

        try {
            await action();
        } catch (error) {
            if (error instanceof UnauthorizedError) {
                setErrorSummary("You are not authorized to perform this operation.  Please try refreshing your browser.");
            }
            else if (error instanceof UserError) {
                setErrorSummary(error.message);
            } else if (error instanceof ServerError) {
                setErrorSummary(`A server error has occurred.  Please try again later! [${error.message}]`);
                console.log(error);
            } else if (error instanceof SyntaxError) {
                setErrorSummary('An error parsing data from server has occurred.  Please try again later.');
                console.log("Syntax error:", error);
            }
            else if (error instanceof TypeError) {
                if (error.message?.startsWith("Failed to fetch")) {
                    setErrorSummary(`Could not connect to server`);
                }
                else {
                    setErrorSummary(`An error has occurred [${error}]`);
                }
                console.log("Type Error", error);
            }
            else {
                console.log("unknown error", error);
                throw error;
            }
        }
    }

    return [
        model,
        {
            updateModel,
            replaceModel,
            bindingsFor,
            handlerFor,
            validateModel,
            validateWithAction,
            modelContainerClassNames,
            ErrorSummary,
            setSummaryError,
            setShowDefaultSummary
        }
    ];
}