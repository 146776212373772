import { FunctionComponent } from "react";
import { ErrorSummaryProps } from "../../hooks";


function buildErrorList(errors: string[]) {
    return (
        <ul className="list-disc ps-4">
            {errors.map((error, i) => <li key={i}>{error}</li>)}
        </ul>
    );
}

export const ErrorAlert: FunctionComponent<ErrorSummaryProps> = ({ errors }) => {
    if (typeof (errors) === 'undefined') {
        return null;
    }
    // Transform errors if it is a string newline delimiters
    if (typeof errors === 'string') {
        const delimiters = /\n/; 
        errors = errors.split(delimiters).map((error) => error.trim()).filter(Boolean);
    }

    if (Array.isArray(errors)) {
        if (errors.length === 0) { return null; }
        if (errors.length === 1) { errors = errors[0]; }
    }

    const children = (typeof (errors) === 'string') ? errors : buildErrorList(errors);
    return (
        <div className="w-full rounded border border-separators-alert bg-background-alert text-separators-alert p-2 px-4">
            {children}
        </div>
    );
}