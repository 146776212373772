import { ClientEndpoint, ClientEndpoints, EndpointStatus } from "@app/shared";
import { useState } from "react";
import { useConfirmDialog, useFetch } from "../../../hooks";
import { useEditModelDialog } from "../../../hooks/useEditModelDialog";
import { Dialog, IconLink, Icons, PageContainer, SkeletonGrid, SortableTable, StatusDot, Table } from "../../shared";
import { DropDownMenu } from "../../shared/DropDownMenu";
import { ClientEndpointEditor } from "../../shared/EditClientEndpoint/ClientEndpointEditor";

type ClientEndpointTableProps = {
    organizationId: string;
    facilityId?: string;
};

export const ClientEndpointTable: React.FC<ClientEndpointTableProps> = (props) => {
    const [endpoints, , endpointHelpers] = useFetch(() => ClientEndpoints.getWithStatusByFacilityId(props.organizationId, props.facilityId ?? ""));
    const [selectedId, setSelectedId] = useState<string>();
    const [history, , historyHelpers] = useFetch(() => selectedId ? ClientEndpoints.getHistory(selectedId) : Promise.resolve(undefined), [selectedId]);
    const [showHistoryDialog, setShowHistoryDialog] = useState(false);
    const endpointDialog = useEditModelDialog(ClientEndpoint,false);
    const confirmDialog = useConfirmDialog();

    const handleAddEndpointOnClick = () => {
        endpointDialog.show(async (toSave: Partial<ClientEndpoint>) => {
            await ClientEndpoints.update(toSave as ClientEndpoint);
            await endpointHelpers.refreshData();
        }, "Add Endpoint", { organizationId: props.organizationId, facilityId: props.facilityId } as Partial<ClientEndpoint>);
    };

    const handleEditEndpointOnClick = (toEdit: ClientEndpoint | undefined) => {
        if (!toEdit) return;

        endpointDialog.show(async (toSave: Partial<ClientEndpoint>) => {
            var result = await ClientEndpoints.update(toSave as ClientEndpoint);
            if (result) {
                await endpointHelpers.refreshData();
            }
        }, "Edit Endpoint", toEdit);
    };

    const handleHistoryEndpointOnClick = (toView: ClientEndpoint | undefined) => {
        if (!toView) return;

        setSelectedId(toView.id);
        //Show history for this endpoint
        setShowHistoryDialog(true);

    }

    const handleDeleteEndpointOnClick = (toDelete: ClientEndpoint | undefined) => {
        if (!toDelete) return;
        confirmDialog.show("Delete Endpoint?", "Are you sure you want to delete this endpoint?", "Delete",
            async () => {
                await ClientEndpoints.deleteEndpoint(toDelete.id);
                await endpointHelpers.refreshData();
            });
    };

    const getStatus = (status: EndpointStatus | undefined) => {
        switch (status) {
            case EndpointStatus.Error:
                return "error";
            case EndpointStatus.Healthy:
                return "ok";
            case EndpointStatus.Warning:
                return "warn";
            default:
                return undefined;
        }
    }

    return (
        <>
            <PageContainer Icon={Icons.Api} title="Client Endpoints" titleRight={<IconLink Icon={Icons.PlusCircle} onClick={handleAddEndpointOnClick}>Add New</IconLink>}>
                <SortableTable
                    initialSortKey="endpointName"
                    isLoading={endpointHelpers.isLoading}
                    emptyValue={<p className="m-5">No Client Endpoints Found</p>}
                    columns={[
                        {
                            header: "Status",
                            width: 50,
                            renderFunc: (e) => <StatusDot status={getStatus(e?.status)} sizeClasses="w-3 h-3" />
                        },
                        {
                            header: "Name",
                            width: 200,
                            dataKey: "endpointName"

                        },
                        {
                            header: "Facility",
                            width: 100,
                            dataKey: "facilityName",
                            renderFunc: (e) => e?.facilityId == null ? "" : e.facilityName
                        },
                        {
                            header: "Details",
                            width: 400,
                            dataKey: "description"
                        },
                        // {
                        //     header: "Event",
                        //     dataKey: "trigger",
                        //     width: 150,
                        //     renderFunc: (e) => getTriggerTypeDisplayName(e!.trigger)
                        // },
                        // {
                        //     header: "Targets",
                        //     dataKey: "targetFilterDescription",
                        //     width: 250
                        // },
                        // {
                        //     header: "Type",
                        //     dataKey: "endpointType",
                        //     renderFunc: (e) => getEndpointTypeDisplayName(e!.endpointType),
                        //     width: 75,
                        // },
                        // {
                        //     header: "URL",
                        //     dataKey: "endpointUrl",
                        //     width: 300
                        // },
                        {
                            header: "",
                            width: 25,
                            renderFunc: (e) => (
                                <DropDownMenu>
                                    <IconLink onClick={() => handleHistoryEndpointOnClick(e)} Icon={Icons.Edit}>View History</IconLink>
                                    <IconLink onClick={() => handleEditEndpointOnClick(e)} Icon={Icons.Edit}>Edit</IconLink>
                                    <IconLink onClick={() => handleDeleteEndpointOnClick(e)} Icon={Icons.Trash}>Delete</IconLink>
                                </DropDownMenu>
                            )
                        }
                    ]}
                    data={endpoints} />
            </PageContainer>
            {confirmDialog.renderDialog()}
            {endpointDialog.renderDialog((model, helpers) => (
                model &&
                <ClientEndpointEditor endpoint={model as ClientEndpoint} onChange={(newModel) => newModel && helpers.replaceModel(newModel)} />
            ))}

            <Dialog show={showHistoryDialog} title={"Recent History"} onOkClick={() => { setShowHistoryDialog(false); setSelectedId(undefined); }}>

                <Table.Container>
                    <Table.Header>
                        <Table.HeaderRow>
                            <Table.HeaderCol>
                                Status
                            </Table.HeaderCol>
                            <Table.HeaderCol>
                                Response Time
                            </Table.HeaderCol>
                            <Table.HeaderCol>
                                Response
                            </Table.HeaderCol>
                        </Table.HeaderRow>
                    </Table.Header>
                    {historyHelpers.isLoading && <SkeletonGrid cols={3} rows={10} />}
                    {!historyHelpers.isLoading && !(history?.length) && <Table.Row><Table.Col className="text-center" colSpan={3}>No history was found.</Table.Col></Table.Row>}
                    {history?.map(h => (
                        <Table.Row key={h.id}>
                            <Table.Col>
                                {h.responseCode ?? "no status"}
                            </Table.Col>
                            <Table.Col>
                                {(h.millisecondsToRespond ?? 0) / 1000}s
                            </Table.Col>
                            <Table.Col>
                                {h.response ?? "no response"}
                            </Table.Col>
                        </Table.Row>
                    )
                    )}
                </Table.Container>
            </Dialog>
        </>
    );
}