
import { Button, CustomClaimValue, DetectionZone, DetectionZoneType, Device, Facilities, Facility, FacilityLayoutConfiguration, IDetectionZone, ScreenLine, Spot, Vector2 } from "@app/shared";
import cn from "classnames";
import React, { MouseEventHandler, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { useCounter, } from "react-use";
import { dismissToast, divideQuadrilateral, toastInfo, toastSuccess, useFacilityCoordsHelper } from "../../../helpers";
import { getShapeCenter, useFacilityCenter } from "../../../helpers/mapHelpers";
import { useConfirmDialog, useHeldKeyboardKeys } from "../../../hooks";
import { useEditModelDialog } from "../../../hooks/useEditModelDialog";
import useRenderingTrace from "../../../hooks/useRenderingTrace";
import { AuthorizeView } from "../AuthorizeView";
import { MaxHeightContainer } from "../charts/MaxHeightContainer";
import { Icons } from "../Icons";
import { InputRow, NumericInput } from "../inputs";
import { LoadingIndicator } from "../loading/LoadingIndicator";
import { MapShape, MapShapeTypes } from "../maps/MapShape";
import { GroupMapShape } from "../maps/MapShapes/GroupMapShape";
import { ParkingSpotGroupMapShape } from "../maps/MapShapes/ParkingSpotGroupMapShape";
import { RectangleMapShape } from "../maps/MapShapes/RectangleMapShape";
import { OmniGoogleMap, OmniMapMethods } from "../maps/OmniGoogleMap";
import { EditTool, EditToolPurpose, EditToolType, EditTools, findToolByShape } from "./EditTool";
import { ShapeConfiguration, shapeToName } from "./ShapeConfiguration";
import { Shape } from "./SvgShape";
import { ShapeDelete } from "./shapeConfigs/ShapeDelete";

type LayoutDesignerProps = {
    facility: Facility;
    onChange?: () => void;
    allowEdit?: boolean;
    className?: string;
    hideWarning?: boolean;
};
enum EditMode { None, Geometry, Layout };

export const LayoutDesigner: React.FC<PropsWithChildren<LayoutDesignerProps>> = ({ facility, onChange }) => {

    const [emState, setEditMode] = useState(EditMode.None);
    const [editTool, setEditTool] = useState<EditTool<Shape>>();
    const initShapes = getFacilityShapes(facility).filter(s => s.$type !== Device.$type && s.$type !== Spot.$type).map(sl => { const val = parseInt(sl.name?.split(' ')?.pop() ?? '0'); return Number.isNaN(val) ? 0 : val; });
    const max = Math.max(...initShapes, 1);
    const [, { inc: incTempId, get: getTempId, reset: resetTempId }] = useCounter(max + 1);
    const coordsHelper = useFacilityCoordsHelper(facility);

    const [facilityCenter, isLoading] = useFacilityCenter(facility);
    const map = useRef<OmniMapMethods>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [toolLocked, setToolLocked] = useState(false);
    const [existingShapes, setExistingShapes] = useState(getFacilityShapes(facility));
    const [copiedShapes, setCopiedShapes] = useState([] as Array<DetectionZone | ScreenLine>);
    const [selectedShapes, setSelected] = useState(Array<MapShapeTypes>);
    const [selectionBox, setSelectionBox] = useState<DetectionZone>();
    const [deleteShape] = useState<ShapeDelete>(new ShapeDelete())
    const confirmDialog = useConfirmDialog();
    class ParkingSpotModel { count: number = 1 };
    const parkingSpotDialog = useEditModelDialog(ParkingSpotModel);
    const showingDialog = useRef(false);

    useRenderingTrace('layout rerender', [facility, onChange, emState, editTool, coordsHelper, isSaving, toolLocked, existingShapes, copiedShapes, selectedShapes, selectionBox]);
    const getLayoutConfiguration = () => {
        var retVal = new FacilityLayoutConfiguration();
        retVal.screenLines = existingShapes?.filter(s => s.$type === ScreenLine.$type) as Array<ScreenLine>;
        retVal.detectionZones = existingShapes?.filter(s => s.$type === DetectionZone.$type) as Array<DetectionZone>;
        retVal.devices = existingShapes?.filter(s => s.$type === Device.$type) as Array<Device>;
        retVal.referencePoint = existingShapes.filter(s => s.$type === Spot.$type)?.[0] as Spot ?? facility.originReferencePointLatLng;
        return retVal;
    };

    const switchEditMode = (newMode: EditMode) => {
        setEditMode(newMode);
    }
    const handleCancel: MouseEventHandler = async (e) => {
        switchEditMode(EditMode.None);
        resetForm();
    }
    const resetForm = () => {
        setEditTool(undefined);
        setEditMode(EditMode.None);
        setSelected([]);
        setExistingShapes(getFacilityShapes(facility));
    }
    const toolClick = (tool: EditTool<any>) => {
        setSelectionBox(undefined);
        setToolLocked(editTool === tool);
        if (editTool === tool) {
            if (toolLocked) {
                setToolLocked(false);
                setEditTool(undefined);
                const selectedShape = existingShapes.find(x => x.id === selectedShapes?.[0]?.id);
                if (selectedShape && !selectedShape?.isComplete()) {
                    setExistingShapes(existingShapes.filter(e => e.id !== selectedShape.id));
                }
            } else {
                setToolLocked(true);
            }
        } else {
            addShape(tool);
        }
    }
    useEffect(() => {//keep next id in check through edits
        const shapes = existingShapes.filter(s => s.$type !== Device.$type && s.$type !== Spot.$type).map(sl => { const val = parseInt(sl.name?.split(' ')?.pop() ?? '0'); return Number.isNaN(val) ? 0 : val; });
        const max = Math.max(...shapes, 1);
        resetTempId(max + 1);
    }, [existingShapes, resetTempId, facility]);

    const addShape = useCallback((shapeBuilder: EditTool<any>) => {
        const selectedShape = existingShapes.find(x => x.id === selectedShapes?.[0]?.id);
        if (shapeBuilder.name !== editTool?.name || selectedShape?.isComplete()) {
            if (selectedShape && !selectedShape.isComplete()) {
                setExistingShapes(existingShapes.filter(e => e.id !== selectedShape.id));
            }

            setEditTool(shapeBuilder);
            const newShape = shapeBuilder.createShape(getTempId());
            setExistingShapes(curr => [...curr, newShape]);
            incTempId();
            setSelected([newShape]);
            return newShape;
        }
    }, [existingShapes, getTempId, setEditTool, incTempId, setSelected, selectedShapes, editTool]);

    const handleSave: MouseEventHandler = async (e) => {
        setIsSaving(true);
        await Facilities.updateLayoutConfiguration(facility.id, getLayoutConfiguration());
        await onChange?.();
        switchEditMode(EditMode.None);
        setIsSaving(false);
    }

    const splitSpot = useCallback((shape: DetectionZone | ScreenLine | Device | Spot, pSpot: Partial<ParkingSpotModel>, shapeBuilder?: EditTool<Shape>) => {
        if (shape.$type !== DetectionZone.$type) {
            return;
        }
        shape = shape as DetectionZone;
        const shapePoints = [...shape.points!]; shapePoints.pop();
        var spotPoints = divideQuadrilateral(shapePoints, pSpot.count ?? 1);
        const spots = [...new Array(pSpot.count)].map((x, idx) => {
            const s = EditTool.ParkingSpot.createShape(getTempId()) as DetectionZone;
            s.zoneType = DetectionZoneType.ParkingSpot;
            s.points = spotPoints[idx].map(pt => new Vector2(pt));
            s.points.push(s.points[0]);
            incTempId();
            return s;
        }, []);

        setExistingShapes(curr => [...curr.filter(s => s?.id !== shape.id), ...spots])
        setSelected(spots);
    }, [getTempId, incTempId]);

    const handleOnShapeChanged = useCallback( async (shape: DetectionZone | ScreenLine | Device | Spot, shouldDelete: boolean): Promise<void> => {
        let canDelete = true;

        if(shouldDelete){
            const result = await Facilities.checkIfCanDeleteShape(facility.id, shape.id)
            if(result){
                canDelete = result.canDelete;
                deleteShape.update(result)
            }       
        }

        setExistingShapes(cv => {
            const newArr = [...cv];
            const sIdx = newArr.findIndex(val => val.id === shape.id);
            if (sIdx > -1) {
                newArr[sIdx] = shape;
            }
            if (shouldDelete && canDelete) {
                newArr.splice(sIdx, 1);
                if (selectedShapes.find(x => x.id === shape.id)) {
                    setSelected(cv => cv.filter(s => s.id !== shape.id));
                }
            }
            return newArr;
        })
        if (shape.isComplete()) {
            if (shape.$type === DetectionZone.$type && editTool?.toolType === EditToolType.ParkingSpot) {
                //confirmDialog.show("Split Spot", "Split this parking spot into x number of equally spaced spots along the longest dimension?", "Split", () => {
                showingDialog.current = true;
                parkingSpotDialog.show(x => splitSpot(shape, x), "Split into even spots", { count: 1 }, () => showingDialog.current = false);
                //});
            }
            if (toolLocked && editTool) {
                addShape(editTool);
            } else {
                setEditTool(undefined)
            }
        }
    }, [setExistingShapes, addShape, editTool, toolLocked, parkingSpotDialog, splitSpot, selectedShapes]);

    const selectShapes = useCallback((shapes: Array<MapShapeTypes>) => {
        setSelected(cv => {            
            if (cv.length === shapes.length && cv.every(ss => shapes.find(x => x.id === ss.id))) {
                return cv;
            }
            return shapes;
        });
    }, [setSelected]);

    const handleGroupUpdate = useCallback((shapes: Array<DetectionZone>): void => {
        setExistingShapes(cv => {
            return cv.map(s => {
                const newShape = shapes.find(x => x.id === s.id);
                return newShape ?? s;
            });
        })
    }, [setExistingShapes]);


    useEffect(() => {
        const shape = selectedShapes[0];
        const selectedItem = document.getElementById('sc' + shape?.id);
        if (selectedItem) {
            selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }

    }, [selectedShapes])
    const cloneShape = useCallback((shape: ScreenLine | DetectionZone) => {
        //find tool then use addShape
        let tool = findToolByShape(shape);
        const clone = Object.assign(tool?.createShape(getTempId())!, { points: shape.points, facilityId: shape.facilityId });
        incTempId();
        return clone as ScreenLine | DetectionZone;
    }, [getTempId, incTempId]);

    const handleCopy = useCallback((shapes?: Array<DetectionZone | ScreenLine>) => {
        if (!shapes?.length) {
            shapes = screenAndZoneOnly(selectedShapes);
        }
        const names = shapes?.map(shapeToName);
        toastSuccess(`Copied ${shapes?.length}  ${names.every(x => x === names[0]) ? names[0] : 'Object'}${(names?.length ?? 0) > 1 ? 's' : ''}`);
        setCopiedShapes(shapes?.map(cloneShape) ?? []);
    }, [selectedShapes, cloneShape]);

    const handlePaste = useCallback((shapes?: Array<DetectionZone | ScreenLine>) => {
        setExistingShapes(curr => [...curr, ...(shapes ?? copiedShapes)]);
        setCopiedShapes([]);
        selectShapes(shapes ?? copiedShapes);
    }, [setExistingShapes, setCopiedShapes, copiedShapes, selectShapes]);

    const handleMapClick = () => {
        if (selectedShapes.length) {
            if (selectedShapes.length === 1 && !selectedShapes[0].isComplete()) {
                return;
            }
            setSelected([]);
        }
    }

    const screenAndZoneOnly = (shapes: Array<Shape>) => shapes.filter(shape => shape.$type === DetectionZone.$type || shape.$type === ScreenLine.$type) as Array<ScreenLine | DetectionZone>;

    const selectionBoxClick = (box?: DetectionZone) => {
        const selectedShape = existingShapes.find(x => x.id === selectedShapes?.[0]?.id);//TODO: this guard is repeated
        if (selectedShape && !selectedShape?.isComplete()) {
            setExistingShapes(existingShapes.filter(e => e.id !== selectedShape.id));
        }
        setEditTool(undefined);

        if (!selectionBox) {
            setSelectionBox(new DetectionZone({ id: 'layoutMapSelectTool', name: 'layoutMapSelectTool' } as IDetectionZone));
        } else if (box?.isComplete()) {
            const outerPoly = new google.maps.Polygon({ paths: [box.points?.map(coordsHelper?.toLatLng!)] })
            const selected = [] as Array<ScreenLine | DetectionZone>;
            existingShapes
                .filter(es => es.$type !== Spot.$type && es.$type !== Device.$type)
                .forEach(es => {
                    const path = (es as ScreenLine | DetectionZone).points?.map(coordsHelper?.toLatLng!);
                    if (path?.every(pCoord => google.maps.geometry.poly.containsLocation(pCoord, outerPoly))) {
                        selected.push(es as ScreenLine | DetectionZone);
                    }
                });
            setSelected(selected);
            const names = selected.map(shapeToName);
            if (selected.length) {
                toastInfo(`Selected ${selected?.length} ${names.every(x => x === names[0]) ? names[0] : 'Object'}${(names?.length ?? 0) > 1 ? 's' : ''}`);
            }
            setSelectionBox(undefined);
        } else if (box) {
            setSelectionBox(box);
        }
    }
    const showSplitDialog = useCallback((shape: MapShapeTypes) => {
        parkingSpotDialog.show(x => splitSpot(shape, x, EditTool.DetectionZone), "Split into even spots", { count: 1 });
    }, [parkingSpotDialog, splitSpot]);

    const handleShapeClick = useCallback((e: google.maps.MapMouseEvent, shape: MapShapeTypes) => {
        if ((e.domEvent as MouseEvent).button === 2 && emState === EditMode.Layout) {//right click
            selectShapes([shape]);
            showSplitDialog(shape);
        } else {
            selectShapes([shape]);
        }
    }, [emState, selectShapes, showSplitDialog]);


    const copyableShapes = [DetectionZone.$type, ScreenLine.$type];

    useHeldKeyboardKeys(keys => {
        const isZPressed = !!(Object.keys(keys.keysDown).find(x => x === 'KeyZ'));
        if (showingDialog.current || emState !== EditMode.Layout) {
            return;//ignore keyboard when dialogs are open
        }
        
        Object.keys(keys.keysDown).forEach(code => {
            switch (code) {
                case 'KeyA':
                    if (isZPressed) {
                        selectShapes(existingShapes.filter(s => s.$type !== Device.$type && s.$type !== Spot.$type));
                    }
                    break;
                case 'KeyC':
                    if (isZPressed && selectedShapes.length) {
                        handleCopy();
                    }
                    break;
                case 'KeyV':
                    if (isZPressed && copiedShapes.length) {
                        handlePaste();
                    }
                    break;
                case 'Delete':
                    if (selectedShapes.length > 0) {
                        confirmDialog.show("Delete", `Are you sure you want to delete ${selectedShapes.length} items`, "Delete", () => {
                            const toDelete = [...selectedShapes];
                            toDelete.forEach(s => handleOnShapeChanged(s, true))
                        }, () => showingDialog.current = false)
                    } else if (selectedShapes.length) {
                        const toDelete = [...selectedShapes];
                        toDelete.forEach(s => handleOnShapeChanged(s, true))
                    }
                    break;
            }
        });

    }, false, [handleCopy, handlePaste, existingShapes, selectedShapes, handleOnShapeChanged, confirmDialog, emState, copiedShapes]);

    const nameSortedShapes = useMemo(() => existingShapes.sort(nameAndNumberSort)
        , [existingShapes]);

    const handleDescriptionClick = (shape: MapShapeTypes) => {
        if (coordsHelper) {
            if (map.current?.map) {
                const panFinished = map.current.map.addListener('idle', () => {
                    panFinished.remove();
                    selectShapes([shape]);
                })
                if(shape.isComplete()){
                    map.current.map.panTo(getShapeCenter(shape, coordsHelper));
                    return;
                }
                  map.current?.reCenter();
            } else {
                selectShapes([shape]);
            }
        }
    }
    const infoTipId = useRef('');
    const infoClick = () => {
        if (infoTipId.current) {
            dismissToast(infoTipId.current);
            infoTipId.current = '';
            return;
        }
        infoTipId.current = toastInfo('Layout Editor Commands', <>
            <p><button className="kbc-button">Z</button> + <button className="kbc-button">C</button> = Copy Selected</p>
            <p><button className="kbc-button">Z</button> + <button className="kbc-button">V</button> = Paste Copied</p>
            <p><button className="kbc-button">Z</button> + <button className="kbc-button"><Icons.ArrowsUpDown size={11} /></button> = Scale Group</p>
            <p><button className="kbc-button">Z</button> + <button className="kbc-button"><Icons.ArrowsLeftRight size={11} /></button> = Rotate Group</p>
            <p><button className="kbc-button">Z</button> + <button className="kbc-button">Shift</button> + <button className="kbc-button"><Icons.ArrowsUpDown size={11} /></button> = Scale Items</p>
            <p><button className="kbc-button">Z</button> + <button className="kbc-button">Shift</button> + <button className="kbc-button"><Icons.ArrowsLeftRight size={11} /></button> = Rotate Items</p>
        </>, 10000);
    }
    const multipleSpotsSelected = useMemo(() => selectedShapes.length > 1 && selectedShapes.every(x => x.$type === DetectionZone.$type), [selectedShapes]);
    const filteredShapes = useMemo(() => multipleSpotsSelected ? existingShapes.filter(es => !selectedShapes.find(ss => ss.id === es.id)) : existingShapes, [existingShapes, multipleSpotsSelected, selectedShapes]);
    const RenderHelpButton = () => {
        if (map.current?.topRightControlDiv) {
            return ReactDOM.createPortal(<Button className="m-2 h-10 w-10 border-0 pl-2" onClick={infoClick} ><Icons.Info size={24} /></Button>, map.current.topRightControlDiv);
        }
    }
    const CenterMap = Icons.Target;
    const showLoading = useMemo(() => isSaving || (!!isLoading) || !!!facilityCenter, [isSaving, isLoading, facilityCenter]);

    return showLoading ? (<div className="flex"><div className="mx-auto mt-20"><LoadingIndicator isLoading={showLoading} /></div></div>) : (
        <div className="flex flex-col xl:flex-row">
            <div className="min-w-[70%]">
                <OmniGoogleMap center={facilityCenter} fitToPolygons={true} ref={map} draggable={!selectionBox && editTool?.toolType !== EditToolType.ParkingSpot} onClick={handleMapClick}>
                    {selectionBox && <RectangleMapShape facility={facility} shape={selectionBox} isSelected={true} isEditable={true} onUpdate={selectionBoxClick} color="blue" />}
                    {(!!copiedShapes?.length) && <GroupMapShape facility={facility} shapes={copiedShapes} isSelected={true} isEditable={true} onClick={handlePaste} color="blue" />}
                    {copiedShapes.map(mp => (<MapShape key={mp.id} shape={mp} facility={facility} isSelected={!!selectedShapes.find(x => x.id === mp.id)} isEditable={selectedShapes.length === 1 && selectedShapes?.[0].id === mp.id && emState === EditMode.Layout} onUpdate={handleOnShapeChanged as any} onClick={handleShapeClick} />))}
                    {filteredShapes.map(mp => (<MapShape key={mp.id} shape={mp} facility={facility} isSelected={!multipleSpotsSelected && !!selectedShapes.find(x => x.id === mp.id)} isSelectable={!copiedShapes.length && editTool === undefined && !selectionBox} isEditable={!copiedShapes.length && selectedShapes.find(ss => ss.id === mp.id) && emState === EditMode.Layout} onUpdate={handleOnShapeChanged as any} onClick={handleShapeClick} />))}
                    {multipleSpotsSelected && <ParkingSpotGroupMapShape facility={facility} spots={selectedShapes as Array<DetectionZone>} onUpdate={handleGroupUpdate} />}
                    {RenderHelpButton()}
                </OmniGoogleMap>


                {emState === EditMode.None &&
                    <div className="flex gap-1 mb-3 xl:mb-0">
                        {map.current?.reCenter && <Button onClick={map.current?.reCenter} className="ml-0"><CenterMap size={24} /></Button>}
                        <AuthorizeView claim={CustomClaimValue.EditSiteLayout} facilityId={facility.id} organizationId={facility.organizationId}>
                            <Button className="btn btn-outline-brand-orange mx-0" onClick={(e) => switchEditMode(EditMode.Layout)}>
                                <i className="fa fa-draw-polygon"></i>
                                Edit Layout
                            </Button>
                        </AuthorizeView></div>
                }
                {emState !== EditMode.None &&
                    <>
                        <div className="flex gap-1">
                            <Button onClick={map.current?.reCenter} className="ml-0"><CenterMap size={24} /></Button>
                            {map.current && <Button onClick={selectionBoxClick as any} className={cn("ml-0", { "border-brand-secondary border-4": !!selectionBox })}><Icons.ObjectGroup size={24} /></Button>}
                            {EditTools.filter(t => (t.toolPurpose === EditToolPurpose.Layout && emState === EditMode.Layout))
                                .map(tool => (
                                    <Button key={tool.toolType} className={cn("flex items-center gap-3 min-w-fit ml-0", { "border-brand-secondary border-4": editTool?.toolType === tool.toolType })} onClick={() => toolClick(tool)} >
                                        {toolLocked && editTool?.toolType === tool.toolType ? <Icons.Lock /> : <tool.icon />} {tool.name}
                                    </Button>
                                ))}

                            <span className="place-content-end ml-auto">
                                <div className="flex">
                                    <Button onClick={handleCancel}>Cancel</Button>
                                    <Button onClick={handleSave} className="mr-0">Save</Button>
                                </div>
                            </span>
                        </div>
                    </>
                }

            </div>

            <MaxHeightContainer minHeight={300} className="w-full">{({ height }) => {
                return (<div style={{ height: `${height}px` }} className="xl:overflow-y-auto"><div className="shape-configurations">
                    {nameSortedShapes?.map(shape => (
                        <ShapeConfiguration
                            id={'sc' + shape.id}
                            key={shape.id ?? shape.name}
                            cantDeleteReason={deleteShape.getReason(shape.id)}
                            facility={facility}
                            shape={shape}
                            availableDevices={facility.devices ?? []}
                            isSelected={!!selectedShapes.find(x => x.id === shape.id)}
                            isEditable={selectedShapes.length < 2 && emState === EditMode.Layout && !!selectedShapes.find(x => x.id === shape.id)}
                            onClick={handleDescriptionClick}
                            onDelete={() => handleOnShapeChanged(shape, true)}
                            onSplit={() => showSplitDialog(shape)}
                            onCopy={copyableShapes.find(x => x === shape.$type) ? s => handleCopy(s) : undefined}
                            onChange={(newShape) => handleOnShapeChanged(newShape as any, false)}
                            toggleEdit={emState === EditMode.None ? () => setEditMode(EditMode.Layout) : undefined}
                        />
                    ))}
                </div></div>);
            }}</MaxHeightContainer>
            {confirmDialog.renderDialog()}
            {parkingSpotDialog.renderDialog((spot, helpers) => (
                <>
                    <InputRow>
                        <NumericInput label="Number of Spots" value={spot.count} onChange={c => helpers.replaceModel({ count: c ?? spot.count ?? 1 })} />
                    </InputRow>
                </>
            ))}
        </div >
    );
}

function getFacilityShapes(facility: Facility): Array<Device | DetectionZone | ScreenLine | Spot> {
    const shapes: Array<Device | DetectionZone | ScreenLine | Spot> = [...facility.detectionZones ?? [], ...facility.screenLines ?? [], ...facility.devices ?? []];
    if (facility.originReferencePointLatLng) {
        facility.originReferencePointLatLng.name = "Reference Point"
        shapes.push(facility.originReferencePointLatLng);
    }
    return shapes;
}

function nameAndNumberSort(a: Device | DetectionZone | ScreenLine | Spot, b: Device | DetectionZone | ScreenLine | Spot): number {
    const aTypeName = shapeToName(a);
    const aName = a.name?.split(aTypeName)

    const bTypeName = shapeToName(b);
    const bName = b.name?.split(bTypeName);
    for (let idx = 0; idx < (aName?.length ?? 0); idx++) {
        if ((bName?.length ?? -1) <= idx) { break; }
        const part = aName![idx];
        const bPart = bName![idx];
        const val = parseInt(part);
        if (!isNaN(val)) {
            const bVal = parseInt(bPart);
            if (!isNaN(bVal)) {
                return val - bVal;
            }
        }
        if (part !== bPart) {
            return part.localeCompare(bPart);
        }
    }
    return a.name?.localeCompare(b.name ?? "") ?? 0;
}