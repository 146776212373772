import { AggregatedTargetAttribute, AggregationPeriod, ApiResult, AverageDailyVolume, Reports, TrafficReportConfiguration, useAuth } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { useContext } from "react";
import { TotalVolumeByDay } from "./TotalVolumeByDay";
import { useFetch } from "../../hooks";
import { ReportContext, getGroupingDisplayName, getZonedDateAsUTC } from "./reportHelpers";
import { Bar, ChartProps, Line } from 'react-chartjs-2';
import { Chart, registerables, TooltipItem } from 'chart.js';
import { extLightThemeColors } from "../../styles/chartThemeColors";
import { format } from "date-fns-tz";
Chart.register(...registerables);

const MonthlyDailyVolumeReport: React.FC<TrafficReportProps & { data: AverageDailyVolume[] }> = props => {
    const { reportConfig } = useContext(ReportContext);
    const { chartType, grouping, chartTimezone } = reportConfig;
    const { numberFormatter } = useAuth();
    const { data, title, subTitle } = props;
    if (!data.length) {
        throw new Error('must data to use chart');
    }

    let datasets = []
    if (grouping === AggregatedTargetAttribute.None) {
        datasets = [{
            label: 'Targets',
            data: data.map(x => ({ x: new Date(x.year, x.month! - 1, 1), y: x.avgVolume })),
            backgroundColor: extLightThemeColors[0]
        }];
    } else {
        const stacked = data.reduce<Map<number, AverageDailyVolume[]>>((acc, item) => {
            if (!acc.has(item.attributeValue!)) {
                acc.set(item.attributeValue!, []);
            }
            acc.get(item.attributeValue!)!.push(item);
            return acc;
        }, new Map()).values();
        datasets = stacked.map((s, idx) => ({
            label: getGroupingDisplayName(grouping, s[0].attributeValue!),
            data: s.map(x => ({ x: new Date(x.year, x.month! - 1, 1), y: x.avgVolume })),
            backgroundColor: extLightThemeColors[idx]
        })).toArray();
    }
    //const labels = datasets?.[0].data?.map(x => format(x.x, 'MM/yy', { timeZone: chartTimezone }));
    const labels = Array.from(new Map(
        data.map(item => [`${item.year}-${item.month}`, item])
    ).values()).map(x =>
        format(new Date(x.year, x.month! - 1, 1), "MMMM yy''", { timeZone: chartTimezone })
    )
    const chartjsProps = {
        data: {
            labels: labels,
            datasets
        },
        options: {
            onClick: (evt, elements) => {
                const year = data[elements[0].index].year;
                const month = data[elements[0].index].month!;
                const rParams = { ...reportConfig }
                rParams.selectedReportId = TotalVolumeByDay.key;

                rParams.inclusiveStart = getZonedDateAsUTC(year, month, 1, reportConfig.chartTimezone);
                rParams.exclusiveEnd = getZonedDateAsUTC(year + (month === 12 ? 1 : 0), (month + 1) % 12, 1, reportConfig.chartTimezone);

                reportConfig.context.setReportConfig(rParams);
            },
            plugins: {
                title: {
                    display: !!title,
                    text: !!!title ? undefined : typeof title !== 'string' ? title(data) : title,
                    font: {
                        size: 24, // Font size for the main title
                        weight: 'bold'
                    },
                },
                subtitle: {
                    display: !!subTitle,
                    text: !!!subTitle ? undefined : typeof subTitle !== 'string' ? subTitle(data) : subTitle,
                    font: {
                        size: 16, // Font size for the main title
                        weight: 'bold'
                    },
                },
                legend: {
                    display: grouping !== AggregatedTargetAttribute.None,
                    position: 'bottom',
                },
                tooltip: {
                    callbacks: {
                        footer: grouping === AggregatedTargetAttribute.None ? undefined : (tooltipItems: TooltipItem<'bar'>[]) => {
                            const total = tooltipItems.reduce((sum, tooltipItem) => {
                                return sum + ((tooltipItem.raw as { x: string, y: number }).y as number);
                            }, 0);
                            return `Total: ${numberFormatter.format(total)}`;
                        }
                    },
                },
            },
            interaction: {
                mode: 'index', // Ensures all datasets for the same label are shown
                intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
            },
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: chartType === 'bar'
                },
                x: {
                    stacked: true
                }
            }
        }
    } as Omit<ChartProps<"line" | "bar", Array<{ x: Date, y: number }>, unknown>, "type">;//), [grouping, numberFormatter, datasets, years]);

    return chartType === 'line' ?
        <Line  {...chartjsProps as any as ChartProps<'line'>} width={props.size.width} height={props.size.height} ></Line> :
        <Bar  {...chartjsProps as any as ChartProps<'bar'>} width={props.size.width} height={props.size.height} ></Bar>;
}
export interface AverageDailyVolumeByMonthLoaderProps extends TrafficReportProps {
    dataToChart?: (props: TrafficReportConfiguration) => ApiResult<AverageDailyVolume[]>;
}

export const AverageDailyVolumeByMonthLoader: React.FC<AverageDailyVolumeByMonthLoaderProps> = props => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => props.dataToChart ? props.dataToChart(searchParams) : Reports.getAverageDailyVolumes(reportConfig.grouping, AggregationPeriod.Month, searchParams), [searchParams, props.dataToChart]);

    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        <MonthlyDailyVolumeReport {...props} data={data!} />
    </ReportLoadingErrorWrapper>);
}

export const AverageDailyVolumeByMonth: IConfiguredTrafficReport = {
    name: "by Month",
    key: "advm",
    description: "This report shows average daily traffic for the specified period",
    component: AverageDailyVolumeByMonthLoader,
    defaultRange: DateOptions.AllData,
    defaultChartType: 'bar',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        requiresYear: false
    }
};