import { useIsMounted } from "@app/shared";
import { MouseEventHandler, useRef, useState } from "react";
import { ModelHelpers, useModel } from ".";
import { Dialog } from "../components";

type Model<T extends {}> = { new(): T };

export function useEditModelDialog<T extends {}>(modelType: Model<T>, showDefaultErrorSummary?: boolean) {
    type OkClickHandlerType = (model: Partial<T>) => Promise<void | boolean> | void | boolean;

    var [showDialog, setShowDialog] = useState(false);
    const [model, modelHelpers] = useModel(modelType);
    const isMounted = useIsMounted();

    var [title, setTitle] = useState<string>("Add/Edit " + modelType);
    var onOkClickHandler = useRef<OkClickHandlerType>();

    const show = (_okClickHandler: OkClickHandlerType, _title: string, defaults?: Partial<T>, onClose?: () => void) => {
        setTitle(_title);
        modelHelpers.setSummaryError(undefined);
        modelHelpers.setShowDefaultSummary(showDefaultErrorSummary ?? true);

        var newModel = new modelType();
        Object.assign(newModel, defaults ?? {});
        modelHelpers.replaceModel(newModel);

        setShowDialog(true);
        onOkClickHandler.current = _okClickHandler;
    }
    const handleOkClick: MouseEventHandler = async (event) => {
        await modelHelpers.validateWithAction(async () => {
            if (onOkClickHandler && onOkClickHandler.current) {
                const doNotClose = await onOkClickHandler.current(model);
                if (doNotClose) return;
            }
            if (isMounted.current) {
                setShowDialog(false);
            }
        });
    }
    const renderDialog = (renderFunc: (model: Partial<T>, helpers: ModelHelpers<T>) => JSX.Element, className?: string) => {

        return (<>
            <Dialog title={title} show={showDialog} onOkClick={handleOkClick} onCancelClick={() => setShowDialog(false)} closeOnOutsideClick={false} okButtonText="Save" className={className}>
                <span className={modelHelpers.modelContainerClassNames}>
                    {renderFunc(model, modelHelpers)}
                    <modelHelpers.ErrorSummary />
                </span>
            </Dialog>
        </>
        );
    }
    return { show, renderDialog, modelHelpers };
}