import { Boolean, IBoolean } from "./System.Boolean.gen";
import { Int32, IInt32 } from "./System.Int32.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { EndpointAttachmentType } from "./Shared.Models.Api.V1.ClientEndpoints.EndpointAttachmentType.gen";
import { EndpointStatus } from "./ClientEndpoints.EndpointStatus.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsEmail, IsNotEmpty, IsOptional } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface IEmailClientEndpoint {
	emailAddress: string | undefined;

	description: string | undefined;

	attachment: EndpointAttachmentType;

	useCustomSmtpServer: boolean | undefined;

	fromEmail: string | undefined;

	username: string | undefined;

	server: string | undefined;

	port: number | undefined;

	password: string | undefined;

	useSsl: boolean | undefined;

	status: EndpointStatus;

	facilityName: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	organizationId: string | undefined;

	endpointName: string;

	enableHeartbeat: boolean;

	secondsBetweenHearbeat: number;

	$type?: string | undefined;
}
export class EmailClientEndpoint {
	constructor(data?: IEmailClientEndpoint) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	@IsOptional()@IsEmail({}, {
	  "message": "The $property field is not a valid e-mail address."
	})
	emailAddress: string | undefined;

	description: string | undefined;

	attachment: EndpointAttachmentType = 0;

	useCustomSmtpServer: boolean | undefined;

	@IsOptional()@IsEmail({}, {
	  "message": "The $property field is not a valid e-mail address."
	})
	fromEmail: string | undefined;

	username: string | undefined;

	server: string | undefined;

	port: number | undefined;

	password: string | undefined;

	useSsl: boolean | undefined;

	status: EndpointStatus = 0;

	facilityName: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	organizationId: string | undefined;

	@IsNotEmpty()
	endpointName: string = "";

	enableHeartbeat: boolean = false;

	secondsBetweenHearbeat: number = 0;

	static $type: string = "CoreApi.Models.ClientEndpoints.EmailClientEndpoint, ";
	$type?: string | undefined = EmailClientEndpoint.$type;
}

