import { EndpointAttachmentType } from "./Shared.Models.Api.V1.ClientEndpoints.EndpointAttachmentType.gen";

export interface IEmailEndpointConfig {
	subject: string | undefined;

	body: string | undefined;

	attachmentType: EndpointAttachmentType;

	$type?: string | undefined;
}
export class EmailEndpointConfig {
	constructor(data?: IEmailEndpointConfig) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	subject: string | undefined;

	body: string | undefined;

	attachmentType: EndpointAttachmentType = 0;

	static $type: string = "Shared.Models.Api.V1.ClientEndpoints.EmailEndpointConfig, ";
	$type?: string | undefined = EmailEndpointConfig.$type;
}

