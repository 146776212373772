import { AggregatedTargetAttribute, FHWAClass, getFHWAClassDisplayName, getLaneDisplayName, getTargetTypeDisplayName, Lane, TargetType, TrafficReportConfiguration } from "@app/shared";
import { isFirstDayOfMonth, isLastDayOfMonth } from "date-fns";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { createContext, useMemo } from "react";
import { GroupedReportConfigurations, SelectedReportConfiguration } from "./ReportComponents";

export const UNGROUPED_SERIES_NAME = 'Targets';
export const ReportContext = createContext<{ reportConfig: SelectedReportConfiguration, searchParams: TrafficReportConfiguration, updateReportConfig: (val: SelectedReportConfiguration) => void }>({ reportConfig: {} as SelectedReportConfiguration, searchParams: {} as TrafficReportConfiguration, updateReportConfig: () => { } });
export const AllTargetTypes = Object.keys(TargetType).map(k => TargetType[k as any]).filter(x => typeof x === 'number') as unknown as TargetType[];
export const AllLanes = Object.keys(Lane).map(k => Lane[k as any]).filter(x => typeof x === 'number') as unknown as Lane[];
export const AllFHWAClasses = Object.keys(FHWAClass).map(k => FHWAClass[k as any]).filter(x => typeof x === 'number') as unknown as FHWAClass[];

export type BarStackMapped<T> = T & { $$BarStackMap: { [key: string]: number } };

export function getGroupingDisplayNameByVal(grouping: AggregatedTargetAttribute) {
    return (val: number) => getGroupingDisplayName(grouping, val);
}
export function getGroupingDisplayName(grouping: AggregatedTargetAttribute, val: number) {
    if (grouping === AggregatedTargetAttribute.FHWAClass) {
        return getFHWAClassDisplayName(val);
    } else if (grouping === AggregatedTargetAttribute.Lane) {
        return getLaneDisplayName(val);
    } else if (grouping === AggregatedTargetAttribute.TargetType) {
        return getTargetTypeDisplayName(val);
    }
    return 'Targets';
}
// export function buildStackedData<TData extends {}>(data: TData[], yValKey: keyof TData, stackKey: keyof TData, addlMatchExclusions?: Array<keyof TData>): Array<BarStackMapped<TData>> {
//     const stackedData = data.reduce((acc, val) => {
//         const existingEntry = findMatchExcept<TData>(acc, val, [stackKey, yValKey, ...addlMatchExclusions ?? []]);
//         if (existingEntry) {
//             const mapKey = val[stackKey] + '';
//             existingEntry.$$BarStackMap[mapKey] = val[yValKey] as any as number;
//         } else {
//             const newEntry = { ...val } as BarStackMapped<TData>;
//             newEntry.$$BarStackMap = {};
//             const mapKey = val[stackKey] + '';
//             newEntry.$$BarStackMap[mapKey] = val[yValKey] as any as number;
//             acc.push(newEntry);
//         }
//         return acc;
//     }, [] as Array<BarStackMapped<TData>>);
//     return stackedData;
// }

// export function groupByKeys<TData extends {}>(data: TData[], keysToGroupBy: (keyof TData)[], yValKey: keyof TData): TData[] {
//     const result: TData[] = [];
//     const map = new Map<string, TData>();

//     for (const obj of data) {
//         const key = keysToGroupBy.map(k => obj[k]).join('-');
//         const existing = map.get(key);
//         if (existing) {
//             (existing[yValKey] as unknown as number) += (obj[yValKey] as unknown as number);
//         } else {
//             map.set(key, { ...obj });
//         }
//     }

//     for (const obj of map.values()) {
//         result.push(obj);
//     }

//     return result;
// }

export const groupBy = function <TItem>(xs: TItem[], key: keyof TItem): { [key: string]: TItem[] } {
    return xs.reduce(function (rv, x) {
        (rv[x[key] as unknown as string] = rv[x[key] as unknown as string] || []).push(x);
        return rv;
    }, {} as { [key: string]: TItem[] });
};

/**
 * 
 * @param acc Array
 * @param val value to search the array for
 * @param exceptionProp single property that need not match to successfully find match in acc Array
 */
// export function findMatchExcept<TData>(acc: Array<TData & { $$BarStackMap: { [key: string]: number } }>, val: TData, exceptProps: Array<keyof TData>): undefined | TData & { $$BarStackMap: { [key: string]: number } } {
//     var searchResult = undefined;
//     acc.some(nextVal => {
//         for (const prop in val)
//             if (exceptProps.every(p => p !== prop) && !doValuesMatch(nextVal[prop], val[prop])) {
//                 return false;
//             }
//         searchResult = nextVal;
//         return true;
//     })
//     return searchResult;
// }
// function doValuesMatch(val1: any, val2: any) {
//     if (val1 && typeof val1['getTime'] === 'function') {
//         if (val2 && typeof val2['getTime'] === 'function') {
//             return val1['getTime']() === val2['getTime']();
//         } else {
//             return false;
//         }
//     }
//     if (val1 !== val2 || (typeof val1 !== typeof val2)) {
//         return false;
//     }
//     return true;
// }

// export function buildLabeler(fvHandler: (str: string, props?: TickRendererProps, idx?: number) => string) {
//     return (props: TickRendererProps): React.ReactNode => {
//         const { formattedValue, ...tickProps } = props;
//         return (<>
//             {
//                 formattedValue?.split("|").map((str: string, idx: number) => {
//                     return <Text key={str} {...tickProps} dy={idx > 0 ? 15 : 0}>{fvHandler(str, props, idx)}</Text>
//                 })
//             }
//         </>);
//     }
// }
// export const hourFormatter = (str: string) => {
//     const hour = parseInt(str);
//     if (Number.isNaN(hour)) { return str; }
//     return `${hour === 0 ? '12' : (hour > 12 ? hour - 12 : hour)}:00 ${hour < 12 ? 'am' : 'pm'}`;
// };
// export const hourLabeler = buildLabeler(hourFormatter);
// export const monthLabeler = buildLabeler(str => getMonthName(parseInt(str) - 1));
// export const defaultLabeler = buildLabeler(x => x);
// export function getMonthName(mIdx: number) {
//     const date = new Date(2000, mIdx, 1);
//     return date.toLocaleString('default', { month: 'long' })
// }

// export const reportCache = {
//     currentData: {} as any,
//     getData(func: Function) {
//         if (!func) {
//             return this.currentData;
//         }
//         this.currentData = func();
//     }
// }


// export function tooltipBuilder<T>(labelFormatter: (d: T) => string, valueFormatter: (d: T) => string, title?: string | ((d: T) => string), footerFormatter?: (d: T) => string) {

//     return (datum: T, colorScale: ScaleOrdinal<string, any, any>): JSX.Element => {
//         title = title ?? `Total Volume`;

//         return (<div>
//             <div className="font-bold mb-2">{typeof title === 'string' ? title : title(datum)}</div>
//             <table className="w-full">
//                 <tbody>
//                     <tr>
//                         <td className="font-medium py-1">{labelFormatter(datum)}:</td>
//                         <td className="py-1">{valueFormatter(datum)}</td>
//                     </tr>
//                     {footerFormatter && <>
//                         <tr><td>‎ ‏</td></tr>
//                         <tr className="font-medium">
//                             <td colSpan={2}>{footerFormatter ? footerFormatter(datum) : null}</td>
//                         </tr>
//                     </>
//                     }
//                 </tbody>
//             </table>
//         </div>)
//     }
// }
//export const targetTypeLabelFormatter = (key: string, stackKey: string) => getTargetTypeDisplayName[parseInt(key)] ?? '*[BAD DATA]';
// export function stackedTooltipBuilder<T>(numberFormatter: Intl.NumberFormat, labelFormatter: (d: BarStackMapped<T>, stackKey: string) => string, valueFormatter?: (d: BarStackMapped<T>, stackKey: string) => string, title?: string | ((d: BarStackMapped<T>) => string), footerFormatter?: (d: T) => string, showTotal?: boolean) {

//     return (datum: BarStackMapped<T>, colorScale: ScaleOrdinal<string, any, any>, stackKey?: string | number | symbol): JSX.Element => {
//         title = title ?? `Total Volume`;
//         let stackTotal = 0;
//         return (<div>
//             <div className="font-bold mb-2">{typeof title === 'string' ? title : title(datum)}</div>
//             <table className="w-full">
//                 <tbody>
//                     {Object.keys(datum.$$BarStackMap ?? []).map(key => {
//                         let valString = valueFormatter ? valueFormatter(datum, key) : datum.$$BarStackMap[key];
//                         if (!valString) { return null; }
//                         if (typeof valString === 'number') {
//                             stackTotal += valString;
//                             valString = numberFormatter.format(Math.round(valString));
//                         }
//                         return (
//                             <tr key={key}>
//                                 <td className="font-medium py-1 font-bold"><span className="font-bold" style={{ color: colorScale ? colorScale(key ?? "") : "" }}>{labelFormatter(datum, key)}</span></td>
//                                 <td className="py-1">{valString}</td>
//                                 {/* <td className="py-1 font-normal">-5%</td> */}
//                             </tr>);
//                     }
//                     )}
//                     {showTotal && <>
//                         <tr><td>‎ ‏</td></tr>
//                         <tr >
//                             <td className="font-medium py-1 font-bold"><span className="font-bold" >Total</span></td>
//                             <td className="py-1">{Math.round(stackTotal)}</td>
//                             {/* <td className="py-1 font-normal">-5%</td> */}
//                         </tr>
//                     </>
//                     }
//                     {footerFormatter && <>
//                         <tr><td>‎ ‏</td></tr>
//                         <tr className="font-medium">
//                             <td colSpan={2}>{footerFormatter ? footerFormatter(datum) : null}</td>
//                         </tr>
//                     </>
//                     }
//                 </tbody>
//             </table>
//         </div>)
//     }
// }

export function getZonedDateAsUTC(year: string | number, month: number, day: string | number, timeZone: string) {
    // Create a Date object in the specified timezone
    const dateString = `${year}-${(month).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T00:00:00`;
    const dateInTimeZone = zonedTimeToUtc(dateString, timeZone);
    return dateInTimeZone;
}

export function useReportTitle(reportProps: SelectedReportConfiguration) {
    //const userLocale = navigator.language || 'en-US';
    const title = useMemo(() => {
        const group = GroupedReportConfigurations.find(x => x.values.find(y => y.key === reportProps.selectedReportId));
        if (group && group.includeLabelInTitle)
            return group.label + ' ' + group.values.find(y => y.key === reportProps.selectedReportId)?.name;
        else if (group)
            return group.values.find(y => y.key === reportProps.selectedReportId)?.name;
        return undefined;
    }, [reportProps.selectedReportId]);

    const titleRange = useMemo(() => {
        if (!reportProps.inclusiveStart || reportProps.inclusiveStart.getTime() === zonedTimeToUtc('2020-01-01T00:00:00', reportProps.chartTimezone).getTime()) return 'All Data';

        const startTime = utcToZonedTime(reportProps.inclusiveStart, reportProps.chartTimezone);
        const endTime = reportProps.exclusiveEnd ? utcToZonedTime(new Date(new Date(reportProps.exclusiveEnd).setMilliseconds(-1)), reportProps.chartTimezone) : new Date();
        const startIsStartOfYear = startTime.getMonth() === 0 && startTime.getDate() === 1;
        const endIsEndOfYear = endTime?.getMonth() === 11 && endTime.getDate() === 31;
        const endIsToday = (!!!reportProps.exclusiveEnd) || (new Date()).getTime() - endTime.getTime() < 1000 * 60 * 60 * 24;//less than a day
        if (endTime && startTime.getMonth() === endTime.getMonth() && startTime.getDate() === 1 && isLastDayOfMonth(endTime) && endTime.getFullYear() === startTime.getFullYear()) { //handle month selected

            return formatInTimeZone(reportProps.inclusiveStart, reportProps.chartTimezone, 'MMMM yyyy');
        }
        if (endTime && startTime.getMonth() === endTime.getMonth() && startTime.getDate() === endTime.getDate() && startTime.getFullYear() === endTime.getFullYear()) { //handle day selected
            return formatInTimeZone(reportProps.inclusiveStart, reportProps.chartTimezone, 'PPPP');//.toLocaleString('default', { month: 'long' }) + ' ' + startTime.getDate() + ' ' + startTime.getFullYear();
        }
        if (isFirstDayOfMonth(startTime) && endTime && isLastDayOfMonth(endTime) && startTime.getFullYear() === endTime.getFullYear() && !endIsEndOfYear) {
            return formatInTimeZone(reportProps.inclusiveStart, reportProps.chartTimezone, 'MMMM') + ' - ' + formatInTimeZone(endTime.setMilliseconds(-1), reportProps.chartTimezone, 'MMMM yyyy');
        }

        const rangeStart = `${startIsStartOfYear ? startTime.getFullYear() : startTime.toLocaleDateString()}`;
        const rangeEnd = `${startIsStartOfYear && endIsEndOfYear && endTime.getFullYear() === startTime.getFullYear() ? '' : endIsToday ? ' - Present' : ' - ' + endTime.toLocaleDateString()}`;
        return rangeStart + rangeEnd;
    }, [reportProps.inclusiveStart, reportProps.exclusiveEnd, reportProps.chartTimezone])

    return [title, titleRange];

}

export function toMPH(velocityMetersPerSecond: number) { return velocityMetersPerSecond * 2.23694; }