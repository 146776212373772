import { Devices, LuxcomClientEndpoint, useAuth } from "@app/shared";
import { useFetch, useModel } from "../../../hooks";
import { Input, InputRow, Select } from "../inputs";
import { ClientEndpointEditorProps } from "./ClientEndpointEditor";
import { HoverTooltip } from "../HoverTooltip";
import { useCallback, useEffect, useMemo } from "react";

type EditLuxcomClientEndpointProps = {
} & ClientEndpointEditorProps;

export const EditLuxcomClientEndpoint: React.FC<EditLuxcomClientEndpointProps> = (props) => {
    const [luxcomEndpoint, helper] = useModel(LuxcomClientEndpoint, props.endpoint as LuxcomClientEndpoint);
    const { currentOrganizationId } = useAuth();
    const [devices, , devicesHelper] = useFetch(() => Devices.getByOrganizationId(currentOrganizationId), [currentOrganizationId]);
    const deviceOptions = useMemo(() => !devices?.length ? [] : devices.sort((a, b) => a.name.localeCompare(b.name))?.map(d => ({ label: `${d.name}   (${d.ipv4Address})`, value: d.id! })), [devices]);
    const onChange = useCallback((newEndpoint: Partial<LuxcomClientEndpoint>) => {
        props.onChange(newEndpoint as LuxcomClientEndpoint);
    }, [props]);

    useEffect(() => {
        if ((luxcomEndpoint.controllingDeviceId?.length ?? 0) > 0 || (devices?.length ?? 0) < 1) {
            return;
        }
        onChange({ ...luxcomEndpoint, controllingDeviceId: devices![0].id });
    }, [devices, luxcomEndpoint, onChange]);



    return (
        <>
            <InputRow><Input label="Endpoint Name" {...helper.bindingsFor("endpointName", onChange)} /></InputRow>
            <InputRow><Input label="Converter IP" {...helper.bindingsFor("ipAddress", onChange)} /></InputRow>
            <InputRow><HoverTooltip hoverable={<Select label="Controlling Device" {...helper.bindingsFor("controllingDeviceId", onChange)} options={deviceOptions} value={luxcomEndpoint.controllingDeviceId} disabled={devicesHelper.isLoading} />}>
                When set all messages to Luxcom controller will be relayed through this device</HoverTooltip>
            </InputRow>
            <InputRow><Input label="Command Port" {...helper.bindingsFor("commandPort", onChange)} /></InputRow >
            <InputRow><Input label="Sys Log Port" {...helper.bindingsFor("syslogPort", onChange)} /></InputRow>
            <InputRow><Input label="Forward UDP Port" {...helper.bindingsFor("forwardUdpPort", onChange)} /></InputRow>
        </>
    );
}