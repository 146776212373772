import { Button, Device, Devices, getApiUrl, Targets } from "@app/shared";
import { useState } from "react";
import ShakaPlayer from "shaka-player-react";
import 'shaka-player-react/dist/controls.css';

import { useInterval } from "react-use";
import { useConfirmDialog, useFetch } from "../../hooks";
import { SkeletonGrid } from "./loading";
import { WebRtcPlayer } from "./WebRtcPlayer";

type DeviceVideoStreamProps = {
    device: Device | undefined;
};

export const DeviceVideoStream: React.FC<DeviceVideoStreamProps> = ({ device }) => {
    //Display the last target image in the preview
    const [lastTarget, , lastTargetHelper] = useFetch(() => device ? Targets.getLastTargetWithPhotoForDevice(device.id) : Promise.resolve(undefined));
    const [streamUrl, setStreamUrl] = useState<string>();
    const confirm = useConfirmDialog();

    useInterval(() => {
        //This is not displayed if the streamUrl has been set
        if (!streamUrl)
            lastTargetHelper.refreshData();
    }, 5000);

    const handleStartStreamOnClick = async () => {
        const newUrl = await Devices.getDeviceStream(device!.id);
        if (!newUrl) {
            confirm.show("Error", "Error requesting video stream.  Please try again later.", "Ok");
            console.log("invalid stream url: ", newUrl);
        } else {
            setStreamUrl(newUrl);
        }
    };


    const isLegacy = (device?.firmwareVersion?.startsWith("1.") && device?.enableWebRtc !== true) === true;


    if (!device) {
        return <SkeletonGrid rows={2} cols={1} />
    }

    return (
        <div className="text-center max-w-6xl mx-auto">
            {streamUrl &&
                <div className="bg-background-left-nav">
                    {isLegacy ?
                        <ShakaPlayer autoPlay src={streamUrl} /> :
                        <WebRtcPlayer device={device} />}
                </div>
            }
            {!streamUrl && lastTarget?.hasPhoto &&
                <img src={getApiUrl(`/api/organization/${device?.organizationId}/device/${device?.id}/target/${lastTarget.externalId}/photo`)} className="mx-auto my-auto bg-background-body" alt="Last Target" style={{ minHeight: 480 }} />
            }
            {!streamUrl &&
                <div>
                    <Button onClick={handleStartStreamOnClick} className="mx-auto">Request Video Feed</Button>
                </div>
            }

            {confirm.renderDialog()}
        </div>
    );

}