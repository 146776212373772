import { CustomClaimValue } from "@app/shared";
import { PropsWithChildren } from "react";
import { useHasClaim } from "../../hooks";

type AuthorizeViewProps = {
  claim: CustomClaimValue,
  facilityId?: string,
  organizationId?: string,
} & PropsWithChildren;

export const AuthorizeView: React.FC<AuthorizeViewProps> = ({ claim, facilityId, organizationId, children }) => {
  const hasPermission = useHasClaim(claim, organizationId, facilityId);

  return <> {hasPermission ? children : <></>}</>;
}
