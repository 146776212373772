import { LuxcomClientEndpointStateJson_V1, ILuxcomClientEndpointStateJson_V1 } from "./Shared.ClientEndpoints.Json.LuxcomClientEndpointStateJson_V1.gen";
import { Guid, IGuid } from "./System.Guid.gen";
import { EndpointStatus } from "./ClientEndpoints.EndpointStatus.gen";

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, IsOptional } from 'class-validator';

/* eslint-enable @typescript-eslint/no-unused-vars */ //JTH
export interface ILuxcomClientEndpoint {
	controllingDeviceId: string | undefined;

	ipAddress: string | undefined;

	commandPort: number;

	syslogPort: number;

	forwardUdpPort: number;

	currentStateJson: ILuxcomClientEndpointStateJson_V1 | undefined;

	status: EndpointStatus;

	facilityName: string | undefined;

	description: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	organizationId: string | undefined;

	endpointName: string;

	enableHeartbeat: boolean;

	secondsBetweenHearbeat: number;

	$type?: string | undefined;
}
export class LuxcomClientEndpoint {
	constructor(data?: ILuxcomClientEndpoint) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		if (data.currentStateJson) { this.currentStateJson = new LuxcomClientEndpointStateJson_V1(data.currentStateJson); }
	}

	controllingDeviceId: string | undefined;

	ipAddress: string | undefined;

	commandPort: number = 0;

	syslogPort: number = 0;

	forwardUdpPort: number = 0;

	currentStateJson: LuxcomClientEndpointStateJson_V1 | undefined;

	status: EndpointStatus = 0;

	facilityName: string | undefined;

	description: string | undefined;

	id: string | undefined;

	facilityId: string | undefined;

	organizationId: string | undefined;

	@IsNotEmpty()
	endpointName: string = "";

	enableHeartbeat: boolean = false;

	secondsBetweenHearbeat: number = 0;

	static $type: string = "CoreApi.Models.ClientEndpoints.LuxcomClientEndpoint, ";
	$type?: string | undefined = LuxcomClientEndpoint.$type;
}

