import { DetectionZone, Device, Facility, FacilityOccupancy, IFacilityOccupancy, ScreenLine, Target } from "@app/shared";
import { useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import { useFacilityCoordsHelper } from "../../../helpers";
import { useFacilityCenter } from "../../../helpers/mapHelpers";
import { useDialog } from "../../../hooks";
import { useParkingViewHub } from "../../../hooks/useLiveViewHub";
import { Icons, PageContainer } from "../../shared";
import { CircleMapShape, MapShape, OmniGoogleMap } from "../../shared/maps";
import { RecentOccupancyTable } from "./RecentOccupancyTable";
import { TargetDetailsDialog } from "./TargetDetailsDialog";

type FacilityLiveViewProps = {
    facility: Facility;
};


export const FacilityParkingView: React.FC<FacilityLiveViewProps> = ({ facility }) => {

    const [hideOverflow, setHideOverflow] = useState(false);
    const [currentOccupancy, setCurrentOccupancy] = useState<FacilityOccupancy>();
    const details = useDialog<Target>();
    const [centerCoords] = useFacilityCenter(facility);
    const [hideDevices] = useLocalStorage(`facility_parkingview_hidedevices_${facility.id}`, false);
    const [hideOutlines] = useLocalStorage(`facility_parkingview_hideoutlines_${facility.id}`, false);
    const facilityShapes = useMemo(() => [...(hideDevices ? [] : facility.devices ?? []), ...(hideOutlines ? [] : facility.detectionZones ?? [])] as Array<Device | ScreenLine | DetectionZone>, [facility, hideDevices, hideOutlines])
    const hasFirstLiveResult = useState(false);
    const fCoords = useFacilityCoordsHelper(facility);


    const zoneCenters = useMemo(() => {
        const centers = {} as { [idx: string]: google.maps.LatLng };
        facility.detectionZones?.forEach(z => {
            if (z.id && z.points?.length) {
                const bounds = new google.maps.LatLngBounds();
                z.points?.forEach(pt => bounds.extend(fCoords?.toLatLng(pt)!));
                centers[z.id] = bounds.getCenter();
            }
        });
        return centers;
    }, [facility.detectionZones, fCoords])

    //Connect our live view
    useParkingViewHub(facility.id, (occupancy: IFacilityOccupancy) => {
        setCurrentOccupancy(new FacilityOccupancy(occupancy));
    });

    return (
        <div className="h-screen flex flex-col">
            <div className="h-[50%]">
                {centerCoords &&
                    <OmniGoogleMap center={centerCoords} fitToPolygons={true} noWrap={true}>
                        {facilityShapes.map(mp => (<MapShape facility={facility} key={mp.id} shape={mp} />))}
                        {zoneCenters && currentOccupancy?.zoneOccupancy?.map(s => s.isOccupied && <CircleMapShape key={s.id} position={zoneCenters[s.detectionZoneId!]} color={facility.devices?.find(d => d.id === s.deviceId)?.color} opacity={confidenceToOpacity(s.isOccupiedConfidence)} radius={5} />)}
                    </OmniGoogleMap>

                }
            </div>
            {/* {(endpointHelpers.isLoading === false && (endpoints?.length ?? 0) < 1) &&
                <div className="flex gap-3 place-items-center">
                    <CheckboxInput label="Hide Devices" checked={hideDevices} onChange={() => setHideDevices(!hideDevices)} />
                    <CheckboxInput label="Hide Outlines" checked={hideOutlines} onChange={() => setHideOutlines(!hideOutlines)} />
                </div>
            } */}
            <div className="flex-grow max-h-96">
                <PageContainer title="Occupancy Changes" Icon={Icons.Timeline} >
                    <div className="max-h-64 overflow-y-auto">
                        <RecentOccupancyTable
                            facility={facility}
                            data={currentOccupancy?.zoneOccupancy ?? []}
                            isLoading={!hasFirstLiveResult}
                        />
                    </div>

                </PageContainer>
            </div>
            {details.renderDialog((val) =>
                <TargetDetailsDialog facility={facility} target={val} onZoomChange={setHideOverflow} />
                , { closeOnOutsideClick: true, showCancelButton: false, className: hideOverflow ? "overflow-hidden max-h-screen w-full max-w-3xl" : undefined })}
        </div>
    );
}

function confidenceToOpacity(occupationConfidence: number): number | undefined {
    if (occupationConfidence > .85) { return 1; }
    if (occupationConfidence < .3) { return 0; }
    return occupationConfidence;
}