import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { AggregatedTargetAttribute, AggregationPeriod, Reports, TrafficReportConfiguration, rawPost } from "@app/shared";
import React, { useMemo, useContext } from "react";
import { useFetch } from "../../hooks";
import { getGroupingDisplayName, ReportContext } from "./reportHelpers";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
import { Bar, ChartProps, Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { extLightThemeColors } from "../../styles/chartThemeColors";

Chart.register(...registerables);

const TargetVelocitiesLoader: React.FC<TrafficReportProps> = props => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const msInDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const dayCount = Math.floor((searchParams.exclusiveEnd.getTime() - searchParams.inclusiveStart.getTime()) / msInDay);
    const aggPeriod = dayCount > 500 ? AggregationPeriod.Day : dayCount > 100 ? AggregationPeriod.Hour : AggregationPeriod.FiveMinutes;
    const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(reportConfig.grouping, aggPeriod, searchParams), [searchParams]);
    const { chartType, grouping, ignoreStoppedTraffic } = reportConfig;
    const { title, subTitle } = props;

    const dataSets = useMemo(() => {
        if (!data?.length) { return []; }

        if (grouping === AggregatedTargetAttribute.None) {
            return [
                {
                    label: "Total Count",
                    data: Array.from(
                        data.reduce((map, item) => {
                            const rounded = Math.min(225, Math.round(item.avgVelocity! * 2.23694));//constant is meters/s to MPH     
                            if (ignoreStoppedTraffic && rounded === 0) {
                                return map;
                            }
                            map.set(
                                rounded,
                                (map.get(rounded) || 0) + item.count
                            );
                            return map;
                        }, new Map<number, number>())
                    ).map(([velocity, count]) => ({
                        x: velocity,
                        y: count
                    })).sort((a, b) => a.x - b.x),
                    borderColor: extLightThemeColors[0],
                    backgroundColor: extLightThemeColors[0],
                    tension: 0.4 // Smooth curved lines
                }
            ];
        } else {
            var attVals = Array.from(new Set(data.map(item => item.attributeValue)));
            return attVals.map((av, idx) => ({
                label: getGroupingDisplayName(grouping, av),
                data: Array.from(
                    data.filter(x => x.attributeValue === av).reduce((map, item) => {
                        const rounded = Math.min(225, Math.round(item.avgVelocity! * 2.23694));//constant is meters/s to MPH
                        if (ignoreStoppedTraffic && rounded === 0) {
                            return map;
                        }
                        map.set(
                            rounded,
                            (map.get(rounded) || 0) + item.count
                        );
                        return map;
                    }, new Map<number, number>())
                ).map(([velocity, count]) => ({
                    x: velocity,
                    y: count
                })).sort((a, b) => a.x - b.x),
                borderColor: extLightThemeColors[idx % extLightThemeColors.length],
                backgroundColor: extLightThemeColors[idx % extLightThemeColors.length],
                tension: 0.4 // Smooth curved lines
            }));
        }

    }, [data, grouping, ignoreStoppedTraffic]);


    const chartjsProps = useMemo(() => ({
        options: {
            plugins: {
                title: {
                    display: !!title,
                    text: !!!title ? undefined : typeof title !== 'string' ? title(data) : title,
                    font: {
                        size: 24, // Font size for the main title
                        weight: 'bold'
                    },
                },
                subtitle: {
                    display: !!subTitle,
                    text: !!!subTitle ? undefined : typeof subTitle !== 'string' ? subTitle(data) : subTitle,
                    font: {
                        size: 16, // Font size for the main title
                        weight: 'bold'
                    },
                },
                legend: {
                    display: grouping !== AggregatedTargetAttribute.None
                },
            },
            interaction: {
                mode: 'index', // Ensures all datasets for the same label are shown
                intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
            },
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: chartType === 'bar'
                },
                x: {
                    stacked: true,
                    type: 'linear'
                }
            }
        }
    } as Omit<Omit<ChartProps<"line" | "bar", (number | [number, number] | null)[], unknown>, "type">, "data">), [grouping, title, subTitle, data, chartType]);

    return <ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        {chartType === 'line' ?
            <Line  {...chartjsProps as ChartProps<'line'>} data={{ datasets: dataSets }} width={props.size.width} height={props.size.height} ></Line> :
            <Bar  {...chartjsProps as ChartProps<'bar'>} data={{ datasets: dataSets }} width={props.size.width} height={props.size.height} ></Bar>}
    </ReportLoadingErrorWrapper>
}


export const TargetVelocitiesReport: IConfiguredTrafficReport = {
    name: "Average Speeds",
    key: "tveld",
    description: "This report shows maximum target speeds for the specified period and allows filtering by speed",
    defaultChartType: 'line',
    component: TargetVelocitiesLoader,
    downloadData: targetVelocitiesExcel as any,//TODO:
    defaultRange: DateOptions.ThisYear,
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        allowTargetTypeGrouping: true,
        allowsIgnoreStoppedTraffic: true,
        requiresYear: false
    }
};


export async function targetVelocitiesExcel(opts: TrafficReportConfiguration): Promise<void> {
    try {
        const response = await rawPost(`/api/report/target-velocities-excel`,
            JSON.stringify(opts),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/octet-stream'
                }
            });
        const blob = await response.blob();
        if (!response.ok) {
            console.error(response.statusText);
            return;
        }

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // Extract the file name from the response headers
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDispositionHeader || '');
        const fileName = matches && matches[1] ? matches[1] : 'SpeedData.xlsx';

        link.download = fileName;
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading Excel file:', error);
    }
}