import { CanDeleteShapeResponse } from "@app/shared";

export class ShapeDelete {
    public shapesThatCantBeDelete: CanDeleteShapeResponse[];

    public constructor() {
        this.shapesThatCantBeDelete = [];
    }

    public update(canDeleteShapeResponse: CanDeleteShapeResponse): void {
        const index = this.shapesThatCantBeDelete.findIndex((shape) => shape.shapeId === canDeleteShapeResponse.shapeId);

        if (canDeleteShapeResponse.canDelete) {
            if (index > -1) {
                this.shapesThatCantBeDelete.splice(index, 1);
            }
        } else {
            if (index > -1) {
                this.shapesThatCantBeDelete[index] = canDeleteShapeResponse;
            } else {
                this.shapesThatCantBeDelete.push(canDeleteShapeResponse);
            }
        }
    }

    public getReason(shapeId: string | undefined): string | undefined {
        if(!shapeId) return
        const shape = this.shapesThatCantBeDelete.find((s) => s.shapeId === shapeId);
        return shape?.reason;
    }
}
