import { startOfDay, subDays } from "date-fns";
import { DateOptions } from "../../reports/IConfiguredTrafficReport";
import { TotalVolumeChart } from "../../reports/TotalVolumeDataLoader";
import { AllTargetTypes, ReportContext } from "../../reports/reportHelpers";
import { zonedTimeToUtc } from "date-fns-tz";
import { AggregatedTargetAttribute, AggregationPeriod, Device, Facility, TotalVolumeByPeriod, TrafficReportConfiguration } from "@app/shared";
import { SelectedReportConfiguration } from "../../reports/ReportComponents";

export type FacilityEventsChartProps = {
    device: Device,
    id?: string;
    title?: string;
    facility: Facility,
    data: Array<TotalVolumeByPeriod>;
    isLoading?: boolean;
} & ChartSizeProps;

export type ChartSizeProps = {
    width?: number;
    height?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
}


export function FacilityEventsChart(props: FacilityEventsChartProps) {
    const { facility, device, width, height, margin, data } = props;
    const start = startOfDay(subDays(new Date(), 13));
    const facilityTimezone = facility.localTimezoneId ?? 'UTC';

    const reportConfig = { selectedReportId: 'tvd', grouping: AggregatedTargetAttribute.TargetType, groupingValues: AllTargetTypes, selectedDateId: DateOptions.Custom, context: { facility: facility, device: device, setReportConfig: () => null }, inclusiveStart: zonedTimeToUtc(start, facilityTimezone), exclusiveEnd: new Date(), facilityIds: undefined, chartTimezone: facilityTimezone, chartType: 'bar', deviceIds: [device.id] } as TrafficReportConfiguration & SelectedReportConfiguration;

    return (
        <ReportContext.Provider value={{ reportConfig: reportConfig, searchParams: reportConfig, updateReportConfig: x => { } }} >
            <TotalVolumeChart
                startDate={reportConfig.inclusiveStart}
                endDate={reportConfig.exclusiveEnd}
                grouping={reportConfig.grouping}
                chartTimezone={reportConfig.chartTimezone}
                chartType="bar"
                data={data}
                title="Recent Targets"
                dateFormat="E MMM d"
                size={{ width: width, height: height, margin: margin }}
                aggregationPeriod={AggregationPeriod.Day}
            />
        </ReportContext.Provider>);
}