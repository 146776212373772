import { Devices as DevicesApi, useAuth } from "@app/shared";
import { FC, useMemo } from "react";
import { useFetch } from "../../hooks";
import { DashboardLayout } from "../layouts";
import { DevicesTable } from "../shared";
import { getLastTargetsForDevices } from "@app/shared/src/api/TargetControllerClient.gen";

export const Devices: FC = (props) => {
    const { currentOrganizationId } = useAuth();
    const [devices, , devicesHelper] = useFetch(() => DevicesApi.getByOrganizationId(currentOrganizationId), [currentOrganizationId]);
    const [lastTargets, , targetsHelper] = useFetch(() => getLastTargetsForDevices(devices?.map(d => d.id!)), [devices]);

    const showLoading = useMemo(() => {
        return !!devicesHelper.isLoading || !!targetsHelper.isLoading
    }, [devicesHelper.isLoading, targetsHelper.isLoading]);   

    return (
        <DashboardLayout centerChildren={"Devices"}>
            {devices?.length === 0 ? <h3>No Devices found</h3> : <DevicesTable devices={devices} isLoading={showLoading} lastTargets={lastTargets} onChange={() => devicesHelper.refreshData()} />}
        </DashboardLayout>
    );
}