import { AggregatedTargetAttribute, Device, Facility, TrafficReportConfiguration } from "@app/shared";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFacilityTZ } from "../../hooks/useFacilityTZ";
import { PrintReportContainer } from "./PrintReportContainer";
import { DefaultReport, GetReport, SelectedReportConfiguration } from "./ReportComponents";
import { AllTargetTypes, ReportContext } from "./reportHelpers";
import { getReportPeriod, ReportParametersForm } from "./ReportParametersForm";
import { zonedTimeToUtc } from "date-fns-tz";


type ReportsViewProps = {
    facility?: Facility;
    device?: Device;
    onChange: () => any;
};
const lastViewedReportConfigSessionKey = "lastViewedReportConfigv1";

export const ReportsView: React.FC<ReportsViewProps> = ({ facility, device }) => {

    const [facilityTZ, , { isLoading }] = useFacilityTZ(facility ?? device?.facilityId!, true);
    const defaultReport = GetReport(DefaultReport.key);
    const [searchParams, setSearchParams] = useState<TrafficReportConfiguration>()
    const [reportConfig, setReportConfig] = useState<SelectedReportConfiguration>();


    const childSetSearchParams = useCallback((params: SelectedReportConfiguration) => {
        setReportConfig(cv => {
            const newRC = { ...cv, ...params };
            if (haveChanged(searchParams, newRC)) {
                setSearchParams(cv => ({ ...cv, ...getSearchParams(newRC) }));
            }
            window.sessionStorage.setItem(lastViewedReportConfigSessionKey, JSON.stringify({ ...getSearchParams(newRC), selectedReportId: newRC.selectedReportId, chartTimezone: newRC.chartTimezone, chartType: newRC.chartType, selectedDateId: newRC.selectedDateId, inclusiveStart: newRC.inclusiveStart, exclusiveEnd: newRC.exclusiveEnd }));
            return newRC;
        });
    }, [searchParams]);


    useEffect(() => {
        if (reportConfig || isLoading) { return; }
        let c = { selectedReportId: defaultReport.key, chartTimezone: facilityTZ, selectedDateId: defaultReport.defaultRange, chartType: defaultReport.defaultChartType, grouping: AggregatedTargetAttribute.TargetType, groupingValues: AllTargetTypes as number[], deviceIds: facility?.devices?.length ? facility.devices.map(d => d.id!) : undefined, ...getReportPeriod(defaultReport.defaultRange, facilityTZ ?? 'UTC'), context: { device: device, facility: facility, setReportConfig: childSetSearchParams } };
        const lastViewedConfig = window.sessionStorage.getItem(lastViewedReportConfigSessionKey);
        if (lastViewedConfig?.length) {
            const sessionConfig = new TrafficReportConfiguration(JSON.parse(lastViewedConfig) as any);
            if (sessionConfig.deviceIds?.every(sessionDev => (c.context.facility?.devices?.find(x => (x.id!) === sessionDev)) || (c.context.device?.id!) === sessionDev)) {// session should be limited to same devices otherwise zones may not line up corrupting periods
                c = { ...c, ...(sessionConfig) };
                const devIds = [] as Array<string>;

                if (c.exclusiveEnd?.toString() === 'Invalid Date')
                    c.exclusiveEnd = undefined;

                if (c.inclusiveStart?.toString() === 'Invalid Date')
                    c.inclusiveStart = undefined;

                c.deviceIds?.forEach(dId => {
                    if (facility && facility.devices?.find(d => d.id === dId)) { devIds.push(dId!); }
                    else if (device?.id === dId) { devIds.push(dId!); }
                });
                c.deviceIds = devIds;
            }
        }
        setReportConfig(c as unknown as SelectedReportConfiguration);
    }, [defaultReport, device, facility, facilityTZ, isLoading, childSetSearchParams, reportConfig])


    const dataSourceName = useMemo(() => {
        if (device) { return device.name; }
        if (reportConfig?.deviceIds?.length && (facility?.devices?.length ?? 0) > reportConfig.deviceIds.length) {
            const names = reportConfig.deviceIds.map(dId => (facility?.devices?.find(d => d.id === dId)?.name)).join(', ');
            return reportConfig.deviceIds?.length > 1 ? names.substring(0, names.length - 2) : names;
        }
        return facility!.name;
    }, [facility, device, reportConfig]);



    useEffect(() => {
        if (isLoading || !!!facilityTZ || !reportConfig) return;
        if (!!!searchParams || facilityTZ !== reportConfig.chartTimezone) {
            childSetSearchParams({ ...reportConfig, chartTimezone: facilityTZ });
        }
    }, [isLoading, facilityTZ, childSetSearchParams, reportConfig, searchParams]);

    if (!searchParams || !reportConfig || (!!!device && !!!facility)) {
        return null;
    }

    return (
        <div className="flex flex-col">
            <ReportContext.Provider value={{ reportConfig: reportConfig, searchParams: searchParams, updateReportConfig: childSetSearchParams }} >
                <ReportParametersForm />
                <PrintReportContainer dataSource={dataSourceName} />
            </ReportContext.Provider>
        </div >
    );
};

function haveChanged(searchParams: TrafficReportConfiguration | undefined, reportConfig: SelectedReportConfiguration): boolean {
    return reportConfig.deviceIds !== searchParams?.deviceIds
        || reportConfig.exclusiveEnd !== searchParams?.exclusiveEnd
        || reportConfig.inclusiveStart !== searchParams?.inclusiveStart
        || reportConfig.grouping !== searchParams?.grouping
        || reportConfig.groupingValues !== searchParams?.groupingValues
        || reportConfig.groupingValues.length !== searchParams?.groupingValues.length
        || reportConfig.facilityIds !== searchParams?.facilityIds;
}

function getSearchParams(cfg: SelectedReportConfiguration): TrafficReportConfiguration {
    const trc = {} as TrafficReportConfiguration;
    for (const k in (new TrafficReportConfiguration())) {
        (trc as any)[k] = (cfg as any)[k as any];
    };
    if (cfg.context.device) {
        if (!cfg.deviceIds?.length) {
            trc.deviceIds = [cfg.context.device.id!];
        }
    } else {
        trc.facilityIds = [cfg.context.facility?.id!];
    }
    if (!cfg.inclusiveStart) {
        trc.inclusiveStart = zonedTimeToUtc('2020-01-01T00:00:00', cfg.chartTimezone);
    }
    if (!cfg.exclusiveEnd) {
        trc.exclusiveEnd = zonedTimeToUtc(new Date().getFullYear() + 1 + '-01-01T00:00:00', cfg.chartTimezone);
    }
    return trc;
}