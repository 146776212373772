export interface ICanDeleteShapeResponse {
	canDelete: boolean;

	shapeId: string | undefined;

	reason: string | undefined;

	$type?: string | undefined;
}
export class CanDeleteShapeResponse {
	constructor(data?: ICanDeleteShapeResponse) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
	}

	canDelete: boolean = false;

	shapeId: string | undefined;

	reason: string | undefined;

	static $type: string = "CoreApi.Models.Layout.CanDeleteShapeResponse, ";
	$type?: string | undefined = CanDeleteShapeResponse.$type;
}

