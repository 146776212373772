import { AggregationPeriod, Reports } from "@app/shared";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { TotalVolumeChart } from "./TotalVolumeDataLoader";
import { useContext, useMemo } from "react";
import { ReportContext } from "./reportHelpers";
import { formatInTimeZone } from "date-fns-tz";
import { useFetch } from "../../hooks";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";

export const BusiestDayReportLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => Reports.busiestDay(searchParams), [searchParams]);
    const { inclusiveStart, exclusiveEnd, grouping } = searchParams;
    const { chartType, chartTimezone } = reportConfig;

    data?.sort(x => x.periodStart?.getTime() ?? 0);
    const title = useMemo(() => {
        if (!!!data || data.length === 0) { return ''; }
        return 'Total Volume ' + formatInTimeZone(data[0].periodStart as Date, chartTimezone, 'MMMM d yyyy');
    }, [data, chartTimezone]);
    const TVC = useMemo(() => {
        return (!data?.length) ? null :
            <TotalVolumeChart
                {...props}
                aggregationPeriod={AggregationPeriod.Hour}
                data={data}
                startDate={data?.[0]?.periodStart ?? inclusiveStart}
                grouping={grouping}
                endDate={data?.[data.length - 1]?.periodStart ?? exclusiveEnd}
                chartTimezone={chartTimezone}
                chartType={chartType as 'bar' | 'line'}
                title={title}
            />
    }, [data, chartType, exclusiveEnd, inclusiveStart, chartTimezone, grouping, props, title]);
    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        {TVC}
    </ReportLoadingErrorWrapper>);
}

export const BusiestDayReport = {
    name: "Busiest Day Report",
    description: "This report shows the volume of the selected traffic types on the busiest day in the selected range",
    component: BusiestDayReportLoader,
    defaultChartType: 'bar',
    defaultRange: DateOptions.ThisYear,
    key: 'bdr',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true
    }
} as IConfiguredTrafficReport;