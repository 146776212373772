import { WebRelayConfig, IWebRelayConfig } from "./Shared.Models.Api.V1.ClientEndpoints.WebRelayConfig.gen";

export interface IWebRelayEndpointConfig {
	relays: IWebRelayConfig[] | undefined;

	$type?: string | undefined;
}
export class WebRelayEndpointConfig {
	constructor(data?: IWebRelayEndpointConfig) {
		if (!data) return;
		if (data.$type && data.$type !== this.$type) console.warn('The $type of data being parsed does not match the type of the object being created (' + this.$type + ')');
		Object.assign(this, data);
		this.relays = data.relays ? data.relays.map((r: any) => new WebRelayConfig(r)) : [];
	}

	relays: WebRelayConfig[] | undefined = [];

	static $type: string = "Shared.Models.Api.V1.ClientEndpoints.WebRelayEndpointConfig, ";
	$type?: string | undefined = WebRelayEndpointConfig.$type;
}

