import { AggregatedTargetAttribute, AggregationPeriod, AverageDailyVolume, Reports } from "@app/shared";
import { useFetch } from "../../hooks";
import { DateOptions, IConfiguredTrafficReport, TrafficReportProps } from "./IConfiguredTrafficReport";
import { AnnualDailyVolumeReport } from "./AverageDailyVolumeByYear";
import { ReportContext } from "./reportHelpers";
import { format } from "date-fns-tz";
import { useContext, useMemo } from "react";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";


const ChartDataLoader: React.FC<TrafficReportProps> = (props: TrafficReportProps) => {
    const { reportConfig, searchParams } = useContext(ReportContext);
    const { grouping } = reportConfig;
    const [data, error, { isLoading }] = useFetch(() => Reports.getAverageDailyVolumes(reportConfig.grouping, AggregationPeriod.Month, searchParams), [searchParams]);

    const ddData = useMemo(() => {
        if (!data?.length) { return []; }
        if (grouping === AggregatedTargetAttribute.None) {
            return Object.values(
                data.reduce<Record<number, AverageDailyVolume>>((acc, item) => {
                    const { year, avgVolume } = item;
                    if (!acc[year] || avgVolume > acc[year].avgVolume) {
                        acc[year] = item;
                    }
                    return acc;
                }, {})
            );
        } else {
            // Group data by year and month
            const groupedByYearMonth = data.reduce<Record<number, Record<number, AverageDailyVolume[]>>>(
                (acc, item) => {
                    const { year, month } = item;
                    if (!acc[year]) acc[year] = {};
                    if (!acc[year][month!]) acc[year][month!] = [];
                    acc[year][month!].push(item);
                    return acc;
                },
                {}
            );
            // Determine the busiest month for each year
            const result: AverageDailyVolume[] = [];
            for (const year in groupedByYearMonth) {
                let busiestMonth: number | null = null;
                let maxVolume = -Infinity;

                for (const month in groupedByYearMonth[year]) {
                    // Sum avgVolume for all attributeValues in the month
                    const totalVolume = groupedByYearMonth[year][month].reduce(
                        (sum, entry) => sum + entry.avgVolume,
                        0
                    );

                    // Update busiest month if this month is busier
                    if (totalVolume > maxVolume) {
                        maxVolume = totalVolume;
                        busiestMonth = +month;
                    }
                }

                // Push all entries for the busiest month into the result array
                if (busiestMonth !== null) {
                    result.push(...groupedByYearMonth[year][busiestMonth]);
                }
            }

            return result;
        }
    }, [data, grouping]);

    const tickLabeler = (year: number) => {
        const datum = ddData?.find(x => x.year === year);
        if (datum) {
            return format(new Date(year, (datum as any).month - 1, 1), 'MMMM yyyy', { timeZone: reportConfig.chartTimezone })
        }
        return year.toString();
    };
    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!ddData?.length} isLoading={isLoading} size={props.size} >
        <AnnualDailyVolumeReport {...props} data={ddData} labelFunc={tickLabeler} />
    </ReportLoadingErrorWrapper>);
}


export const DesignDayVolumesReport = {
    name: "Annual Design Day Volume Report",
    description: "This report shows the month with the maximum average daily volume for every year",
    component: ChartDataLoader,
    defaultChartType: 'bar',
    defaultRange: DateOptions.AllData,
    key: 'add',
    config: {
        requiresMonth: false,
        allowsCustomRange: true,
        requiresYear: false,
        allowTargetTypeGrouping: true
    }
} as IConfiguredTrafficReport;