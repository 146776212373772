import { Device, Vector2, degreesToRadians } from "@app/shared";
import { useContext, useEffect, useRef } from "react";
import { Vector } from "ts-matrix";
import { MapContext, MapObjArray, circle } from "../../../../helpers";
import { ICoordsHelper, useFacilityCoordsHelper } from "../../../../helpers/coordsHelpers";
import { MapShapeProps } from "../MapShape";


export const DeviceMapShape: React.FC<MapShapeProps<Device>> = ({ facility, shape, isSelected, isEditable, isSelectable, onUpdate, onClick }) => {
    const [parentMap, setBounds] = useContext(MapContext);
    const mapObjects = useRef([] as MapObjArray);
    const deviceMarker = useRef<google.maps.Marker>();
    const fov = useRef<google.maps.Polygon>();
    const fCoordsHelper = useFacilityCoordsHelper(facility);

    useEffect(() => { // handle creation/update
        if (!deviceMarker.current && parentMap && typeof shape?.positionX === 'number') {
            const newPolys = initPolys(shape, parentMap, fCoordsHelper);
            if (newPolys) {
                deviceMarker.current = newPolys.marker;
                fov.current = newPolys.fov;
                mapObjects.current = [deviceMarker.current!, fov.current!];
                setBounds(curr => { fov.current?.getPath().getArray().forEach(pt => curr.extend(pt)); return curr; });
            }
        } else if (deviceMarker.current) {
            const updated = updatePolys(deviceMarker.current, fov.current!, shape, parentMap, fCoordsHelper);
            fov.current = updated.fov;
            deviceMarker.current = updated.marker;
            mapObjects.current = [deviceMarker.current!, fov.current!];
        }
        return () => {
            mapObjects.current?.forEach(mo => { mo?.setVisible(false); mo.setMap(null); });
            deviceMarker.current = undefined;
            mapObjects.current = [];
        }
    }, [parentMap, shape]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { //editable    
        const dv = deviceMarker.current;
        let dvDragListener = null as null | google.maps.MapsEventListener, dvDragEndListener = null as null | google.maps.MapsEventListener;

        if (!dv || !isEditable) {
            dv?.setDraggable(false);
            return;
        }

        dv.setDraggable(true);
        dv.addListener('dragend', (d: google.maps.MapMouseEvent) => {
            if (d.latLng) {
                const offset = fCoordsHelper.fromLatLng(d.latLng);
                const updatedDev = Object.assign(new Device(), { ...shape, positionX: offset.x, positionY: offset.y });
                onUpdate?.(updatedDev);
            }
        });
        dv.addListener('drag', (d: google.maps.MapMouseEvent) => {
            if (d.latLng) {
                dv.setPosition(d.latLng);
                const offset = fCoordsHelper.fromLatLng(d.latLng);
                const updatedDev = Object.assign(new Device(), { ...shape, positionX: offset.x, positionY: offset.y })
                fov.current?.setPath(createFoV(updatedDev, undefined, fCoordsHelper).getPath());
            }
        });

        return () => {
            dvDragListener?.remove();
            dvDragEndListener?.remove();
        }

    }, [isEditable, shape, parentMap, deviceMarker])//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { // handle selection from list
        if (!isSelected || !deviceMarker.current || !fov.current) { return; }
        animateSelect(fov.current, deviceMarker.current, parentMap);
    }, [isSelected, parentMap, deviceMarker]);

    useEffect(() => {//selection from map
        if (!isSelectable) { return; }
        let click: google.maps.MapsEventListener;
        let clickFov: google.maps.MapsEventListener;
        if (onClick && parentMap && deviceMarker.current && shape) {
            deviceMarker.current.set('clickable', true);
            fov.current?.set('clickable', true);
            click = deviceMarker.current.addListener('click', (e: google.maps.MapMouseEvent) => onClick?.(e, shape));
            clickFov = fov.current!.addListener('click', (e: google.maps.MapMouseEvent) => onClick?.(e, shape));
        }
        return () => {
            deviceMarker.current?.set('clickable', false);
            fov.current?.set('clickable', false);
            click?.remove();
            clickFov?.remove();
        }
    }, [onClick, parentMap, deviceMarker, shape, isSelectable])
    return null;


    function initPolys(device: Device, parentMap: google.maps.Map, coordsHelper: ICoordsHelper): { marker: google.maps.Marker, fov: google.maps.Polygon } | undefined {
        if (typeof device.positionX !== 'number') { return undefined; }
        const coords = coordsHelper.toLatLng(new Vector2({ x: device.positionX, y: device.positionY }));

        const positionCircle = new google.maps.Marker({
            icon: circle(device.color ?? "blue"),
            position: coords,
            map: parentMap,
            draggable: false,//!!onDrag,
        });

        const poly = createFoV(device, parentMap, coordsHelper);
        return { marker: positionCircle, fov: poly };

    }
}


function updatePolys(marker: google.maps.Marker, fov: google.maps.Polygon, shape: Device | undefined, parentMap: google.maps.Map | undefined, coordsHelper: ICoordsHelper): { marker: google.maps.Marker | undefined, fov: google.maps.Polygon | undefined } {
    [marker, fov].forEach(poly => poly.setMap(parentMap ?? null));
    if (!parentMap || !shape) {
        return { marker: undefined, fov: undefined };
    }
    const coords = coordsHelper.toLatLng(new Vector2({ x: shape.positionX, y: shape.positionY }));
    marker.setPosition(coords)
    fov.setPath(createFoV(shape, undefined, coordsHelper).getPath());
    return { marker, fov };
}


function createFoV(device: Device, parentMap: google.maps.Map | undefined, coordsHelper: ICoordsHelper) {
    const fovPath = getPathForFieldOfView(device.positionX, device.positionY, device.height, device.desiredTiltDegrees, 90, 30, 1);
    const fovPoints = fovPath.split(',').filter(x => x.length > 1).map(pt => {
        pt = pt.substring(2);
        const spIdx = pt.indexOf(' ');
        return { lng: parseFloat(pt.substring(0, spIdx)), lat: parseFloat(pt.substring(spIdx + 1)) };
    });
    const ptsToDraw = rotate(fovPoints, new Vector2({ x: device.positionX, y: device.positionY }), 180 + device.headingDegrees);
    const coordsToDraw = ptsToDraw.map(pt => new Vector2({ y: pt.lat, x: pt.lng })).map(coordsHelper.toLatLng);
    // Construct the polygon.
    const poly = new google.maps.Polygon({
        paths: coordsToDraw,
        strokeColor: device.color ?? "#5900ff",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: device.color ?? "#5900ff",
        fillOpacity: 0.35,
        geodesic: false,
        map: parentMap,
        clickable: false,
        zIndex: 5
        //draggable: !!onDrag
    });
    return poly;
}

function getGroundIntersection(linePoint: Vector, lineDirection: Vector) {
    const planePoint = new Vector([1, 1, 0]);
    const planeNormal = new Vector([0, 0, 1]);

    //float t = (Vector3.Dot(planeNormal, planePoint) - Vector3.Dot(planeNormal, linePoint)) / Vector3.Dot(planeNormal, Vector3.Normalize(lineDirection));
    const t = (planeNormal.dot(planePoint) - planeNormal.dot(linePoint)) / planeNormal.dot(lineDirection.normalize());
    var distance = lineDirection.normalize().scale(t);
    return linePoint.add(distance);
}

function getPathForFieldOfView(originX: number, originY: number, height: number, elevationAngle: number, azimuthFov: number, elevationFov: number, scaleRatio: number) {
    const apexHeight = height;
    const maxLength = 75;

    //TODO: Display the boresight point:
    //var boresight = GroundIntersection(apex, new Vector3(0, (float)Math.Sin(declinationAngle), -(float)Math.Cos(declinationAngle))).To2D(windowCenter);

    const declinationAngle = degreesToRadians(90 - Math.abs(elevationAngle));
    const fovWidth = degreesToRadians(108);
    const fovHeight = degreesToRadians(40);

    const apex = new Vector([0, 0, apexHeight]);
    const segments = 30;
    const pointsToDraw: { x: number, y: number }[] = [];

    for (let i = 0; i < segments; i++) {
        const theta = 2 * Math.PI * i / segments;
        let x = fovWidth / 2 * Math.cos(theta);
        let y = fovHeight / 2 * Math.sin(theta);

        if (declinationAngle + y > Math.PI / 2) {
            y = degreesToRadians(89) - declinationAngle;
        }

        const topRay = new Vector([Math.sin(x), Math.sin(declinationAngle + y), -Math.cos(declinationAngle + y)]);
        let top = getGroundIntersection(apex, topRay);

        const distance = top.subtract(apex);
        if (distance.length() > maxLength) {
            top = top.scale(maxLength / distance.length());
        }

        pointsToDraw.push({ x: top.at(0) + originX, y: top.at(1) + originY });
    }

    const pathCommands = pointsToDraw.map((point, index) => {
        const command = index === 0 ? 'M' : 'L';
        return `${command} ${point.x} ${point.y}`;
    });

    pathCommands.push('Z');

    return pathCommands.join(',');
}

function rotate(points: { lng: number; lat: number; }[], center: Vector2, headingDegrees: number): { lng: number; lat: number; }[] {
    if (points.length < 1) return points;
    const rotated = points.map(latLng => {
        var point = new Vector2({ x: latLng.lng, y: latLng.lat });
        var rotatedLatLng = rotatePoint(point, center, headingDegrees);
        return { lat: rotatedLatLng.y, lng: rotatedLatLng.x };
    });
    return rotated;
}
function rotatePoint(point: Vector2, center: Vector2, angle: number) {
    var angleRad = angle * Math.PI / 180.0;
    return {
        x: Math.cos(angleRad) * (point.x - center.x) - Math.sin(angleRad) * (point.y - center.y) + center.x,
        y: Math.sin(angleRad) * (point.x - center.x) + Math.cos(angleRad) * (point.y - center.y) + center.y
    };
}


function animateSelect(line: google.maps.Polyline, point: google.maps.Marker, map?: google.maps.Map) {

    const vertexCircles = line.getPath().getArray().map(pos => {
        return getCircleForPosition(pos, map);
    });
    vertexCircles.push(getCircleForPosition(point.getPosition()!, map));
    point.setVisible(false);
    setTimeout(() => {
        vertexCircles.forEach(c => {
            c.setVisible(false);
            c.setMap(null);
        });
        point.setVisible(true);
    }, 1000);
}

function getCircleForPosition(pos: google.maps.LatLng, map: google.maps.Map | undefined): google.maps.Marker {
    return new google.maps.Marker({
        icon: circle("blue"),
        position: pos,
        map: map,
        clickable: false,
        animation: google.maps.Animation.DROP
    });
}

function getFovPoint(height: number, azimuthAngle: number, elevationAngle: number, scaleRatio: number) {
    var eps = 0.1;
    var elevationAngleRads = degreesToRadians(Math.min(90 + elevationAngle, 90 - eps)); //we do not want this angle to be higher than - or equal to - 90 degrees
    var y = Math.tan(elevationAngleRads) * height;

    var distanceToGround = height / Math.cos(elevationAngleRads);
    var azimuthAngleRads = degreesToRadians(azimuthAngle);
    var x = Math.tan(azimuthAngleRads) * distanceToGround;

    return [x / scaleRatio, y / scaleRatio];
}