import { TrafficReportProps } from "./IConfiguredTrafficReport";
import { Reports, TotalVolumeByPeriod, AggregationPeriod, AggregatedTargetAttribute, useAuth } from "@app/shared";
import { useContext, useMemo } from "react";
import { getGroupingDisplayName, ReportContext } from "./reportHelpers";
import { Chart, registerables, TooltipItem } from 'chart.js';
import { extLightThemeColors } from "../../styles/chartThemeColors";
import { useFetch } from "../../hooks";
import { formatInTimeZone } from "date-fns-tz";
import { Bar, ChartProps, Line } from "react-chartjs-2";
import { ReportLoadingErrorWrapper } from "./ReportLoadingErrorWrapper";
Chart.register(...registerables);

export interface TotalVolumeDataLoaderProps extends TrafficReportProps {
    aggregationPeriod: AggregationPeriod,
    onClick?: (date: Date) => void
}
export interface TotalVolumeChartProps extends TrafficReportProps {
    aggregationPeriod: AggregationPeriod,
    grouping: AggregatedTargetAttribute,
    data: TotalVolumeByPeriod[],
    startDate: Date,
    endDate: Date,
    dateFormat?: string,
    chartTimezone: string,
    chartType: 'bar' | 'line'
    onClick?: (date: Date) => void
}
export const TotalVolumeDataLoader: React.FC<TotalVolumeDataLoaderProps> = (props) => {
    const { searchParams, reportConfig } = useContext(ReportContext);
    const [data, error, { isLoading }] = useFetch(() => Reports.getTotalVolume(reportConfig.grouping, props.aggregationPeriod, searchParams), [searchParams]);

    if (reportConfig.chartType === 'other') {
        throw new Error('unsupported chart type for total volume');
    }
    return (<ReportLoadingErrorWrapper error={error} hasNoData={!!!data?.length} isLoading={isLoading} size={props.size} >
        <TotalVolumeChart {...props} grouping={reportConfig.grouping} data={data!} startDate={searchParams.inclusiveStart} endDate={searchParams.exclusiveEnd} chartTimezone={reportConfig.chartTimezone} chartType={reportConfig.chartType} />
    </ReportLoadingErrorWrapper>);
}

export const TotalVolumeChart: React.FC<TotalVolumeChartProps> = (reportProps) => {
    const { aggregationPeriod, size, data, grouping, chartTimezone, chartType, title, subTitle } = reportProps;
    const { numberFormatter } = useAuth();
    const sortedData = useMemo(() => [...data].sort((a, b) => a.periodStart!.getTime() - b.periodStart!.getTime()), [data])
    const xValues = useMemo(() => Array.from(new Set(sortedData.map(item => item.periodStart!.getTime()) ?? [])).map(x => new Date(x)), [sortedData]);

    const dateFormat = useMemo(() => {
        if (reportProps.dateFormat) {
            return reportProps.dateFormat;
        }
        if (!xValues.length) {
            return 'yyyy';
        }
        const getDateFormat = (numPeriods: number, start: Date, end: Date) => {
            const startMonth = formatInTimeZone(start, chartTimezone, 'MMMM');
            const endMonth = formatInTimeZone(end, chartTimezone, 'MMMM');
            const startYear = formatInTimeZone(end, chartTimezone, 'yyyy');
            const endYear = formatInTimeZone(end, chartTimezone, 'yyyy');
            const isSameMonth = startMonth === endMonth;
            const isSameYear = startYear === endYear;
            if (aggregationPeriod === AggregationPeriod.Hour) {
                return numPeriods > 24 ? 'M/dd HH:00' : 'H:mm';
            } else if (aggregationPeriod === AggregationPeriod.Year) {
                return 'yyyy';
            } else if (aggregationPeriod === AggregationPeriod.Month) {
                return numPeriods > 12 ? 'MMMM yyyy' : 'MMMM';
            } else {
                if (isSameYear) {
                    if (isSameMonth) {
                        return 'd';
                    }
                    return 'MMM d';
                }
                return 'MMM dd yyyy';
            }
        }
        return getDateFormat(xValues.length, new Date(xValues[0]), new Date(xValues[xValues.length - 1]));
    }, [xValues, aggregationPeriod, chartTimezone, reportProps.dateFormat]);

    let datasets = []
    if (grouping === AggregatedTargetAttribute.None) {
        datasets = [{
            label: 'Targets',
            data: sortedData.map(x => x.count),
            backgroundColor: extLightThemeColors[0]
        }];
    } else {
        const stacked = sortedData.reduce<Map<number, TotalVolumeByPeriod[]>>((acc, item) => {
            if (!acc.has(item.attributeValue!)) {
                acc.set(item.attributeValue!, []);
            }
            acc.get(item.attributeValue!)!.push(item);
            return acc;
        }, new Map()).values();
        datasets = stacked.map((s, idx) => ({
            label: getGroupingDisplayName(grouping, s[0].attributeValue!),
            data: s.map(x => x.count),
            backgroundColor: extLightThemeColors[idx]
        })).toArray();
    }

    const chartjsProps = {
        data: {
            labels: xValues.map(x => formatInTimeZone(x, chartTimezone, dateFormat)),
            datasets
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            // onClick: (evt, elements) => {
            //     const year = data[elements[0].index].year;
            //     const rParams = { ...reportConfig };
            //     rParams.selectedReportId = AverageDailyVolumeByMonth.key;
            //     rParams.inclusiveStart = getZonedDateAsUTC(year, 1, 1, reportConfig.chartTimezone);
            //     rParams.exclusiveEnd = getZonedDateAsUTC((year) + 1, 1, 1, reportConfig.chartTimezone);
            //     reportConfig.context.setReportConfig(rParams);
            // },
            plugins: {
                title: {
                    display: !!title,
                    text: !!!title ? undefined : typeof title !== 'string' ? title(sortedData) : title,
                    font: {
                        size: 24, // Font size for the main title
                        weight: 'bold'
                    },
                },
                subtitle: {
                    display: !!subTitle,
                    text: !!!subTitle ? undefined : typeof subTitle !== 'string' ? subTitle(sortedData) : subTitle,
                    font: {
                        size: 16, // Font size for the main title
                        weight: 'bold'
                    },
                },
                legend: {
                    display: grouping !== AggregatedTargetAttribute.None,
                    position: 'bottom',
                    // align: 'start'
                },
                tooltip: {
                    callbacks: {
                        footer: (tooltipItems: TooltipItem<'bar'>[]) => {
                            if (grouping === AggregatedTargetAttribute.None) {
                                return null;
                            }
                            const total = tooltipItems.reduce((sum, tooltipItem) => {
                                return sum + (tooltipItem.raw as number);
                            }, 0);
                            return `Total: ${numberFormatter.format(total)}`;
                        }
                    }
                },
            },
            interaction: {
                mode: 'index', // Ensures all datasets for the same label are shown
                intersect: false, // Ensures tooltips show even if cursor isn't directly over a bar
            },
            scales: {
                y: {
                    beginAtZero: true,
                    stacked: chartType === 'bar'
                },
                x: {
                    stacked: true
                }
            }
        }
    } as Omit<ChartProps<"line" | "bar", (number | [number, number] | null)[], unknown>, "type">;//), [grouping, numberFormatter, datasets, years]);

    return chartType === 'line' ?
        <Line  {...chartjsProps as ChartProps<'line'>} width={size.width} height={size.height} ></Line> :
        <Bar  {...chartjsProps as ChartProps<'bar'>} width={size.width} height={size.height} ></Bar>
}
// function createWrappedMap<T, U>(array1: T[], array2: U[]): Map<T, U> {
//     const map = new Map<T, U>();
//     for (let i = 0; i < array1.length; i++) {
//         const wrappedIndex = i % array2.length;
//         map.set(array1[i], array2[wrappedIndex]);
//     }
//     return map;
// }